import { TenantPermissions } from '../../../../enums/tenantPermissions';
import { routes } from '../../../../router/routes';
import { NavigationTabProps } from '../../../../shared/types/tabProps';

export const portfolioTabs: NavigationTabProps[] = [
  {
    label: 'overview',
    route: routes.portfolio.overview,
    value: 'overview',
  },
  {
    label: 'SmartNfts',
    route: routes.portfolio.smartCollectibles.root,
    value: 'smartCollectibles',
    permission: TenantPermissions.NFT,
  },
  {
    label: 'smartShares',
    route: routes.portfolio.smartShares.root,
    value: 'smartShares',
    permission: TenantPermissions.SHARE,
  },
  {
    label: 'smartBonds',
    route: routes.portfolio.smartBonds.root,
    value: 'smartBonds',
    permission: TenantPermissions.BOND,
  },
];
