import { styled } from 'styled-components';

import { ThemedIconContainer } from '../theme-icon/styles/iconContainer';

export const ToggleButtonContainer = styled.div`
  width: fit-content;
`;

export const ButtonWrapper = styled.div<{ $isContentOpen: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;

  ${ThemedIconContainer} {
    transition: transform 0.2s ease-out;
    transform: rotate(${({ $isContentOpen }) => ($isContentOpen ? '180deg' : '0deg')});
  }
`;

export const ContentBodyContainer = styled.div<{ $isContentOpen: boolean }>`
  display: grid;
  grid-template-rows: ${({ $isContentOpen }) => ($isContentOpen ? '1fr' : '0fr')};
  transition: all 0.2s ease-out;

  * {
    visibility: ${({ $isContentOpen }) => ($isContentOpen ? 'visible' : 'hidden')};
    pointer-events: ${({ $isContentOpen }) => ($isContentOpen ? 'auto' : 'none')};
  }
`;

export const TogglableWrapper = styled.div`
  min-height: 0;
`;

export const ContentWrapper = styled.div<{ $isContentOpen: boolean }>`
  padding: 0.75rem 0;
  opacity: ${({ $isContentOpen }) => ($isContentOpen ? '100' : '0')};
  transition: opacity 0.2s ease-out;
`;
