import { BlurredBackground, Heading, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { useTenantConfig } from '../../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';

import {
  ContentContainer,
  HeadingWrapper,
  Icon,
  VerifyWrapper,
  Wrapper,
  Layout,
  LogoContainer,
} from './expiredLink.styles';

export const ExpiredLink: FC = () => {
  const { textTranslation } = useTextTranslation();
  const tenantConfig = useTenantConfig();
  const theme = useTheme();

  return (
    <Layout>
      <BlurredBackground palette="error" customBlurBackground={tenantConfig.blurBackground}>
        <ContentContainer>
          <LogoContainer src={tenantConfig.logoLarge} />
          <VerifyWrapper>
            <Wrapper>
              <HeadingWrapper>
                <Icon src={tenantConfig.errorIcon} />
                <Heading headingType="h3" color={theme.v2.text.headingPrimary} fontWeight="bold">
                  {textTranslation('home.theLinkHasExpired')}
                </Heading>
                <Text lineHeight="medium" textColor="hue500">
                  {textTranslation('home.theLinkYouTryingAccess')}
                </Text>
              </HeadingWrapper>
            </Wrapper>
          </VerifyWrapper>
        </ContentContainer>
      </BlurredBackground>
    </Layout>
  );
};
