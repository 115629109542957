import { mapDate } from '@metaswiss/lib';
import {
  BankIcon,
  CoinHandIcon,
  CoinUnbrokenIcon,
  PageStateContainer,
  ThemedIcon,
  TrendUpIcon,
} from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../../api/msApi';
import { AssetDetails } from '../../../../../../components/asset-details/AssetDetails';
import { ItemProps } from '../../../../../../components/asset-details/types';
import { AssetType } from '../../../../../../enums/assetType';
import { ApiResource } from '../../../../../../enums/resource.enum';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../../router/routes';
import { getQueryKey } from '../../../../../../shared/helpers/getQueryKey.helper';
import { LoadingError } from '../components/loading-error/LoadingError';

export const SingleBond = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();
  const { id: bondTransactionId } = useParams();

  const {
    data: bondTransactionData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.SINGLE_TRANSACTION, bondTransactionId),
    queryFn: () => {
      return api.clientTransaction.getClientTransactionById(bondTransactionId as string);
    },
    onError: (error) => {
      const response = error as Response;

      //TODO - test this condition once the backend is updated with 404 status code
      if (response.status === 404) navigate(routes.pageNotFound);
    },
    enabled: !!bondTransactionId,
  });

  const bondItems: ItemProps[] = useMemo(
    () => [
      {
        icon: <ThemedIcon icon={CoinHandIcon} customColor={theme.v2.icon.primary} />,
        subText: textTranslation('account.pricePerBond'),
        text: bondTransactionData?.orderItem?.convertedAmount ?? '',
      },
      {
        icon: <ThemedIcon icon={CoinUnbrokenIcon} customStrokeColor={theme.v2.icon.primary} />,
        subText: textTranslation('account.quantity'),
        text: bondTransactionData?.orderItem?.quantity?.toString() ?? '',
      },
      {
        icon: <ThemedIcon icon={BankIcon} customStrokeColor={theme.v2.icon.primary} />,
        subText: textTranslation('global.paymentMethod'),
        text: textTranslation(`global.${bondTransactionData?.paymentType?.toLowerCase()}`),
      },
      {
        icon: <ThemedIcon icon={TrendUpIcon} customStrokeColor={theme.v2.icon.primary} />,
        subText: textTranslation('account.smartBondCode'),
        text: bondTransactionData?.orderItem?.code ?? '',
      },
    ],
    [bondTransactionData, theme]
  );

  if (isError || isLoading || !bondTransactionData) {
    return <LoadingError isLoading={isLoading} isError={isError} data={bondTransactionData} refetch={refetch} />;
  }

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      showError={true}
      isError={isError || !bondTransactionData}
      onTryAgain={refetch}
      textTranslation={textTranslation}
      showEmptyState={false}
    >
      <AssetDetails
        heading={textTranslation('account.smartBondHeading')}
        amount={bondTransactionData?.totalConvertedAmount}
        date={mapDate(bondTransactionData?.createdAt)}
        items={bondItems}
        documents={bondTransactionData?.invoices}
        assetType={AssetType.TRANSACTIONS}
        transactionStatus={bondTransactionData.status}
      />
    </PageStateContainer>
  );
};
