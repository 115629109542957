import { ShareOfferingFullResponse, UserResponse } from '@metaswiss/api';
import { formatAmount, regionMapper } from '@metaswiss/lib';
import {
  BankIcon,
  BarChartNoBorderIcon,
  BaseCard,
  CalendarCheck,
  CardTip,
  CoinUnbrokenIcon,
  DataStatusPage,
  ErrorDialog,
  ErrorRegionIcon,
  FileUp2,
  FloatButton,
  Globe,
  LineChart,
  PageStateContainer,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { BarChart } from '@metaswiss/ui-kit/src/iconography/BarChart';
import { ClockBackward } from '@metaswiss/ui-kit/src/iconography/ClockBackward';
import { CurrencyDollarIcon } from '@metaswiss/ui-kit/src/iconography/CurrencyDollarIcon';
import { Icon } from '@metaswiss/ui-kit/src/iconography/Icon';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { Documents } from '../../../../../components/documents/Documents';
import { OfferingPopup } from '../../../../../components/offering-popup/OfferingPopup';
import { ProductDetails } from '../../../../../components/product-details/ProductDetails';
import { ProductInfo } from '../../../../../components/product-info/ProductInfo';
import { ProductPrice } from '../../../../../components/product-price/ProductPrice';
import { ProductType } from '../../../../../enums/productType.enum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { UserStatus } from '../../../../../enums/userStatus.enum';
import { AppState, useAppState, useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { useTenantConfig } from '../../../../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { defaultUser } from '../../../../../shared/helpers/defaultUser';
import { getFormattedLanguageName } from '../../../../../shared/helpers/getFormattedLanguageName.helper';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import { currencyMapper } from '../../../../../shared/mappers/currency.mapper';
import { CardOfferingDetailsHolder } from '../../shared/components/card-offering-details-holder/CardOfferingDetailsHolder';

import { LeftContentWrapper, LowerRowWrapper, RightContentWrapper, UpperRowWrapper } from './singleShare.styles';

interface MyCustomError {
  message: string;
  messageCode: string;
  code: number;
}

export interface Response {
  body: MyCustomError;
}

export type DocumentType = {
  id: string;
  name: string;
  url?: string;
};

export const SingleShare: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const tenantConfig = useTenantConfig();

  const { id: shareId } = useParams();
  const queryClient = useQueryClient();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle } = useHeaderOptions();

  const currency = useAppState((state: AppState) => state.currency);
  const user = useAppState((state: AppState) => state.user) || defaultUser;
  const setUser = useAppState((state) => state.setUser);

  const [isOpenOfferingPopup, setIsOpenOfferingPopup] = useState<boolean>(false);
  const [isOpenBenefitCard, setIsOpenBenefitCard] = useState<boolean>(false);
  const [isNoResourcesModalOpen, setIsNoResourcesModalOpen] = useState<boolean>(false);
  const [regionError, setRegionError] = useState<boolean>(false);
  const [contentfulError, setContentfulError] = useState<boolean>(false);

  const {
    data: shares,
    isLoading: isLoadingShares,
    isError: isErrorShares,
    refetch: refetchShares,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.SHARES, 'ALL'),
    queryFn: () => api.shares.getShares(),
    onError: (error) => {
      const response = error as Response;
      if (response?.body?.code === 405) {
        setRegionError(true);
      }
    },
    retry: false,
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries(getQueryKey(ApiResource.SHARES, 'ALL'));
    };
  }, []);

  const {
    data: benefits,
    isLoading: isLoadingBenefits,
    isError: isErrorBenefits,
    refetch: refetchBenefits,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.SHARE_BENEFITS, currentLanguage),
    queryFn: () => api.contentful.getSalesInformation(getFormattedLanguageName(currentLanguage), 'share-benefits'),
  });

  useLayoutEffect(() => {
    if (!tenantConfig.singleShare) {
      removeTabs();
      setHeaderTitle(textTranslation('offering.shareDetails'));
    }
  }, [removeTabs, textTranslation, setHeaderTitle]);

  useQuery({
    queryKey: getQueryKey(ApiResource.USER_RESPONSE),
    queryFn: () => {
      return api.users.getUserResponse();
    },
    onSuccess: (response: UserResponse) => {
      setUser(response);
    },
  });

  const id = useMemo(() => {
    return shareId || (shares?.items[0] as unknown as ShareOfferingFullResponse)?.id;
  }, [shareId, shares?.items]);

  const {
    data: share,
    isFetching: isLoadingShare,
    isError: isErrorShare,
    refetch: refetchShare,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.SHARE, `${id}/${currentLanguage}`),
    queryFn: () => api.shares.getShareOffering(id as string, currentLanguage),
    enabled: !!id,
    onError: (error) => {
      const response = error as Response;
      if (response?.body?.code === 300) {
        setContentfulError(true);
      }
    },
    retry: false,
  });

  const isLoading = useMemo(() => {
    if (regionError) {
      return false;
    }
    if (contentfulError) {
      return false;
    }
    if (shareId) {
      return isLoadingShare || isLoadingBenefits;
    }

    return isLoadingShare || isLoadingBenefits || isLoadingShares;
  }, [regionError, contentfulError, shareId, isLoadingShare, isLoadingBenefits, isLoadingShares]);

  const isError = useMemo(() => {
    if (shareId) {
      return isErrorShare || isErrorBenefits;
    }
    return isErrorShare || isErrorBenefits || isErrorShares;
  }, [shareId, isErrorShare, isErrorBenefits, isErrorShares]);

  const onTryAgain = async () => {
    await Promise.all([refetchShare(), refetchBenefits(), refetchShares]);
  };

  const handleInvestButtonClick = () => {
    if (share && share.availableSharesInRound < 0) {
      setIsNoResourcesModalOpen(true);
      return;
    }
    if (user?.isFullyRegistered && user?.isActive && user?.status === UserStatus.APPROVED) {
      navigate(`${routes.payment.root}`, {
        state: { item: share, productType: ProductType.ACTIONS },
      });
    } else {
      setIsOpenOfferingPopup(true);
    }
  };

  const shareInfoPrice = !share
    ? []
    : [
        {
          icon: CoinUnbrokenIcon,
          text: 'offering.minimalInvestment',
          iconColor: theme.v2.icon.primary,
          value: formatAmount(
            share.rawMinimalInvestment ?? 0,
            currency?.decimals,
            currency?.rate,
            currency?.currencyCode
          ),
        },
        {
          icon: BankIcon,
          iconColor: theme.v2.icon.primary,
          text: 'offering.totalEquityCapital',
          value: share.totalEquityCapital,
        },
        {
          icon: ClockBackward,
          iconColor: theme.v2.surface.action2,
          subColor: theme.v2.surface.action2,
          text: 'offering.roundDuration',
          subText: textTranslation('offering.round') + ' ' + '11',
          value: `${share.roundStartDate} - ${share.roundEndDate}`,
        },
      ];

  const shareInfoItems = !share
    ? []
    : [
        {
          icon: CalendarCheck,
          iconColor: theme.v2.text.disabled,
          text: 'offering.issuingDate',
          value: share.issuingDate,
        },
        {
          icon: FileUp2,
          iconColor: theme.v2.text.disabled,
          text: 'offering.nominalValuePerShare',
          value: formatAmount(
            share.rawTotalEquityCapital / share.totalShares ?? 0,
            currency?.decimals,
            currency?.rate,
            currency?.currencyCode
          ),
        },
        {
          icon: Icon,
          iconColor: theme.v2.text.disabled,
          text: 'offering.type',
          value: textTranslation(`offering.${share.type.toLowerCase()}`),
        },
        {
          icon: Globe,
          iconColor: theme.v2.text.disabled,
          text: 'offering.availableTo',
          value: regionMapper(share.regions),
        },
        {
          icon: CurrencyDollarIcon,
          iconColor: theme.v2.text.disabled,
          text: 'offering.sellInCurrencies',
          value: currencyMapper(share?.currencies ?? []),
        },
        {
          icon: BarChart,
          iconColor: theme.v2.text.disabled,
          text: 'offering.isin',
          value: share?.isin.toString() ?? '',
        },
      ];

  const isNotCustomError = useMemo(() => {
    if (contentfulError) {
      return false;
    }
    if (regionError) {
      return false;
    }
    return isError;
  }, [contentfulError, isError, regionError]);

  const isShareInfoEmptyState = useMemo(() => {
    return shareInfoItems?.length === 0 || contentfulError;
  }, [shareInfoItems.length, contentfulError]);

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      isError={isNotCustomError}
      showError={true}
      showEmptyState={true}
      isEmptyState={isShareInfoEmptyState}
      onTryAgain={onTryAgain}
      emptyStateTitle={'offering.nothingHereYet'}
      emptyStateContent={'offering.noSharesAvailable'}
      textTranslation={textTranslation}
      customEmptyStateComponent={
        regionError && (
          <DataStatusPage
            icon={<ThemedIcon icon={ErrorRegionIcon} size={'full'} />}
            title={textTranslation('global.nothingHere')}
            content={textTranslation('global.notPossibleShares')}
            action={onTryAgain}
            iconWidth={'12rem'}
            iconHeight={'12rem'}
            buttonVariant={'outlined'}
            buttonColor={'neutral'}
          />
        )
      }
    >
      <FloatButton
        label={benefits?.heading[0] || ''}
        onClick={() => setIsOpenBenefitCard(!isOpenBenefitCard)}
        isOpenFloatButtonCard={isOpenBenefitCard}
        icon={BarChartNoBorderIcon}
        closedFloatButtonCard={() => setIsOpenBenefitCard(false)}
        backgroundColor={theme.v2.surface.action1}
        hoverBackgroundColor={theme.v2.text.actionHover}
      >
        <AnimatePresence>
          {isOpenBenefitCard && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '120%' }}
              transition={{ type: 'spring', mass: 1, stiffness: 406, damping: 25 }}
            >
              <CardTip
                title={benefits?.heading[0] || ''}
                descriptionList={benefits?.content || []}
                icon={BarChartNoBorderIcon}
                borderColor={theme.v2.colors.primary100}
                background={theme.v2.surface.informationLight}
                iconColor={theme.v2.icon.primary}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </FloatButton>
      <OfferingPopup user={user} isOpen={isOpenOfferingPopup} setIsOpen={setIsOpenOfferingPopup} />
      <ErrorDialog
        primaryText={textTranslation('offering.productNotAvailable', { product: 'Shares' })}
        secondaryText={textTranslation('offering.noProductAvailable', { product: 'Shares' })}
        isOpen={isNoResourcesModalOpen}
        close={() => setIsNoResourcesModalOpen(false)}
        primaryButtonText={textTranslation('offering.confirm')}
        onPrimaryButtonClick={() => setIsNoResourcesModalOpen(false)}
      />
      <CardOfferingDetailsHolder heading={textTranslation('offering.priceChart')} fitHeight={false}>
        <BaseCard
          height={'21.75rem'}
          disableStateEffects={true}
          padding={'1rem'}
          override={{
            borderRadius: theme.v2.componentsRadius.cards.default,
            backgroundColor: theme.v2.surface.primary,
            borderColor: theme.v2.border.primary,
          }}
        >
          <LineChart data={share?.priceChart || []} interval={'year'} />
        </BaseCard>
      </CardOfferingDetailsHolder>
      <ProductPrice
        formatValue={formatAmount(
          share?.rawPricePerShare ?? 0,
          currency?.decimals,
          currency?.rate,
          currency?.currencyCode
        )}
        type="offering.pricePerShare"
        isShares={true}
        items={shareInfoPrice}
        handleInvestButtonClick={handleInvestButtonClick}
        disableSticky={true}
      />
      <UpperRowWrapper>
        <LeftContentWrapper>
          {share && (
            <ProductDetails
              product={ProductType.ACTIONS}
              companyInfo={`${share.issuer.issuerName}, ${share.issuer.country.name}`}
              address={share.issuer.address}
              productOffered={share.sharesOffered}
              productSold={share.sharesSold}
              totalCollected={share.totalCollected}
              investors={share.investors}
              productType={'Shares'}
              total={share.totalShares}
              value={share.value}
              progressBarFilledColor={theme.v2.colors.primary100}
              progressBarEmptyColor={theme.v2.colors.primary25}
              remainings={'offering.sharesRemaining'}
              issuerLogoUrl={share.issuer.issuerLogoUrl}
            />
          )}
        </LeftContentWrapper>
        <RightContentWrapper>
          <ProductInfo items={shareInfoItems} headingCardTitle="global.details" product={ProductType.ACTIONS} />
        </RightContentWrapper>
      </UpperRowWrapper>

      <LowerRowWrapper>
        <Documents documents={share?.offeringDocuments || []} palette="primary" />
      </LowerRowWrapper>
    </PageStateContainer>
  );
};

export const SingleShareHandler = () => {
  const { id } = useParams();
  const tenantConfig = useTenantConfig();

  if (tenantConfig.singleShare && id) {
    return <Navigate to={routes.offering.smartShares.root} />;
  }

  return <SingleShare />;
};
