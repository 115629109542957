import { RemSize, Text } from '@metaswiss/ui-kit';
import { FC, useState } from 'react';

import { HoverIconWrapper, TooltipContent, TooltipHolder, TooltipWrapper } from './inputTooltip.styles';

export type Props = {
  description: string;
  backgroundColor?: string;
  contentColor?: string;
  holderBackgroundColor?: string;
  holderSize?: RemSize;
  leftPosition: RemSize;
  topPosition: RemSize;
  leftHoverPosition?: RemSize;
  topHoverPosition?: RemSize;
};

export const InputTooltip: FC<Props> = ({
  contentColor,
  backgroundColor,
  description,
  leftPosition,
  topPosition,
  leftHoverPosition = '1.063rem',
  topHoverPosition = '1.063rem',
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onMouseEnter = () => {
    setIsVisible(true);
  };

  const onMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <>
      <HoverIconWrapper
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        $leftPosition={leftHoverPosition}
        $topPosition={topHoverPosition}
      />

      {isVisible && (
        <TooltipWrapper>
          <TooltipHolder $leftPosition={leftPosition} $topPosition={topPosition} $backgroundColor={backgroundColor}>
            <TooltipContent $backgroundColor={backgroundColor}>
              <Text fontSize={'xsm'} lineHeight={'extraSmall'} color={contentColor}>
                {description}
              </Text>
            </TooltipContent>
          </TooltipHolder>
        </TooltipWrapper>
      )}
    </>
  );
};
