import { ErrorDialog, useAppWizard } from '@metaswiss/ui-kit';
import { AnimatePresence } from 'framer-motion';
import React, { FC, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { SuccessfullyPurchasedSharedComponent } from '../../../components/successfully-purchased-shared-component/SuccessfullyPurchasedSharedComponent';
import { ProductType } from '../../../enums/productType.enum';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes';

import { usePaymentContext } from './context/PaymentContext';
import { PaymentBondShare } from './payment-item-components/PaymentBondShare';
import { PaymentNft } from './payment-item-components/PaymentNft';
import { PaymentRouteState, SuccessPaymentRouteState } from './payment.types';
import { MetaSwissWrapper, Wrapper } from './styles/success.styles';

export const Payment: FC = () => {
  const theme = useTheme();
  const isMetaswiss = theme.v2.tenant === 'MetaSwiss';
  const { state, pathname }: { state: PaymentRouteState | SuccessPaymentRouteState; pathname: string } = useLocation();
  const { setItem, setProductType, productType, isError, setIsError } = usePaymentContext();
  const { textTranslation } = useTextTranslation();
  const { setActiveStep } = useAppWizard();

  const isSuccessScreen = pathname.includes('success');
  const successState = state as SuccessPaymentRouteState;
  const paymentState = state as PaymentRouteState;

  useEffect(() => {
    if (paymentState) {
      setItem(paymentState.item);
      setProductType(paymentState.productType);
    }
  }, [setItem, setProductType, paymentState]);

  if (!state) {
    return <Navigate to={routes.offering.root} />;
  }

  const PaymentWrapper = isMetaswiss ? MetaSwissWrapper : Wrapper;

  return (
    <React.Fragment key={productType}>
      <ErrorDialog
        primaryText={textTranslation('global.somethingWentWrong')}
        secondaryText={textTranslation('global.errorTryAgain')}
        isOpen={isError}
        close={() => setIsError(false)}
        primaryButtonText={textTranslation('global.tryAgain')}
        onPrimaryButtonClick={() => {
          setIsError(false);
          setActiveStep(1);
        }}
      />
      <AnimatePresence mode="wait">
        {!isSuccessScreen ? (
          <>
            {productType === ProductType.NFT && <PaymentNft />}
            {productType !== ProductType.NFT && <PaymentBondShare />}
          </>
        ) : (
          <PaymentWrapper key={'success'}>
            <SuccessfullyPurchasedSharedComponent
              item={successState.item}
              productType={successState.productType || ProductType.ACTIONS}
              paymentMethod={successState.paymentMethod}
              paymentResponse={successState.paymentResponse}
              currency={successState.currency}
            />
          </PaymentWrapper>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};
