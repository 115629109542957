import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Filler,
  LinearScale,
  LineElement,
  Plugin,
  PointElement,
  ScriptableContext,
  TimeScale,
  TimeSeriesScale,
  Tooltip,
} from 'chart.js';
import { FC, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useTheme } from 'styled-components';

import 'chartjs-adapter-date-fns';

import { getDateLabels } from './utils/getDateLabels';
import { getGradientColor } from './utils/getGradientColor';
import { getHexToRgba } from './utils/getHexToRgba';

interface LineProps {
  options: ChartOptions<'line'>;
  data: ChartData<'line'>;
}

type Props = {
  data: { x: string; y: number }[];
  interval: 'year' | 'quarter' | 'month' | 'day';
};

const alwaysShowLastTooltipPlugin: Plugin = {
  id: 'alwaysShowLastTooltip',
  afterDraw: (chart) => {
    const tooltip = chart?.tooltip;
    if (!tooltip) {
      return;
    }
    if (!chart.tooltip.getActiveElements().length) {
      chart.tooltip.setActiveElements(
        [
          {
            datasetIndex: 0,
            index: chart.data.datasets[0].data.length - 2,
          },
        ],
        {
          x: chart.chartArea.right,
          y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
        }
      );
      chart.update();
    }
  },
};

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Filler,
  Tooltip,
  alwaysShowLastTooltipPlugin
);

export const LineChart: FC<Props> = ({ data, interval }) => {
  const theme = useTheme();

  const formattedData = useMemo(() => {
    const latestDate = new Date();
    const latestData = {
      x: latestDate.toISOString().split('T')[0],
      y: data[data.length - 1].y,
    };

    const yData = [...data.map((d) => d.y), latestData.y];

    return {
      x: [...data.map((d) => d.x), latestData.x],
      y: yData,
      pointRadius: [...data.map(() => 10), 0],
      pointHoverRadius: [...data.map(() => 10), 0],
      pointBorderWidth: [...data.map(() => 8), 0],
      suggestedMaxY: Math.max(...yData) + 5,
    };
  }, [data]);

  const lineAndPointStyling = {
    borderColor: theme.v2.icon.primary,
    backgroundColor: (context: ScriptableContext<'line'>) => getGradientColor(context, theme),
    pointHoverBackgroundColor: theme.v2.icon.primary,
    pointBackgroundColor: theme.v2.icon.primary,
    pointHoverBorderColor: getHexToRgba(theme.v2.icon.primary, 0.2),
    pointBorderColor: getHexToRgba(theme.v2.icon.primary, 0.2),
    pointHoverBorderWidth: 16,
    stepped: true,
    fill: true,
  };

  const chartData: ChartData<'line'> = {
    labels: formattedData.x,
    datasets: [
      {
        ...lineAndPointStyling,
        pointRadius: formattedData.pointRadius,
        pointHoverRadius: formattedData.pointHoverRadius,
        pointBorderWidth: formattedData.pointBorderWidth,
        data: formattedData.y,
      },
    ],
  };

  const options: LineProps['options'] = {
    scales: {
      y: {
        afterBuildTicks: (chart) => {
          chart.ticks.forEach((tick, index) => {
            tick.major = index % 5 === 0 ? true : false;
          });
        },
        suggestedMin: 0,
        suggestedMax: formattedData.suggestedMaxY,
        ticks: {
          color: theme.colors.neutrals.hue700,
          backdropColor: 'transparent',
          stepSize: 1,
          callback: (tickValue, index) => {
            return index % 5 === 0 ? tickValue : '';
          },
        },
        grid: {
          drawTicks: true,
          drawOnChartArea: true,
          tickColor: theme.v2.border.primaryHover,
          color: (context) => {
            if (context?.tick.major) {
              return theme.v2.border.primaryHover;
            } else {
              return 'transparent';
            }
          },
        },
      },
      x: {
        type: 'timeseries',
        time: {
          unit: interval,
        },
        grid: {
          drawTicks: true,
          drawOnChartArea: false,
          offset: false,
          tickColor: theme.v2.border.primaryHover,
        },
        ticks: {
          backdropColor: 'transparent',
          crossAlign: 'far',
          callback: (value) => getDateLabels(value, interval),
          padding: 10,
        },
      },
    },
    animation: {
      easing: 'easeInOutQuart',
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        yAlign: 'bottom',
        xAlign: 'center',
        displayColors: false,
        position: 'nearest',
        intersect: false,

        callbacks: {
          title: () => '',
          label: (context) => {
            return context.parsed.y + ' CHF/Share';
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return <Line data={chartData} width={'100%'} height={'100%'} options={options} />;
};
