import { FieldValues, Path, UseFormSetValue } from 'react-hook-form';

export const setFieldValues = <T extends FieldValues>(item: T, setValue: UseFormSetValue<T>) => {
  if (item) {
    Object.keys(item).forEach((key: string) => {
      const a = key as Extract<keyof T, string>;
      setValue(key as Path<T>, item[a]);
    });
  }
};
