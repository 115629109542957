import { routes } from '../../router/routes';
import { regex } from '../regex/regex';

type SearchClientHeader = {
  translationKey: string;
  route: string;
  regex?: RegExp;
};
export const searchClientHeader: SearchClientHeader[] = [
  { translationKey: 'nftsSearch', route: routes.offering.smartCollectibles.collectibles, regex: regex.numbersOnly },
  { translationKey: 'nftsSearch', route: routes.portfolio.smartCollectibles.nftHistory, regex: regex.numbersOnly },
];
