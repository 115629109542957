import { formatAmountWithoutDecimals, mapDate } from '@metaswiss/lib';
import {
  BarChartWithoutBorder,
  CoinHandIcon,
  CoinUnbrokenIcon,
  DataStatusPage,
  PageStateContainer,
  ThemedIcon,
  UserProfileIcon,
} from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useLayoutEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { AssetDetails } from '../../../../../components/asset-details/AssetDetails';
import { ItemProps } from '../../../../../components/asset-details/types';
import { AssetType } from '../../../../../enums/assetType';
import { ProductType } from '../../../../../enums/productType.enum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';

export const ShareDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle } = useHeaderOptions();
  const { assetId } = useParams();

  const {
    data: details,
    isLoading: isLoadingDetails,
    isError: isErrorDetails,
    refetch: refetchDetails,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.PORTFOLIO, assetId),
    queryFn: () => api.clientPortfolio.getSmartShareInvestmentByOrderId(assetId || ' '),

    enabled: !!assetId,
  });

  useLayoutEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('portfolio.assetDetails'));
  }, [removeTabs, textTranslation, setHeaderTitle]);

  //TODO - update mocked nft details once we got a new enpoint for it
  const itemsData: ItemProps[] = useMemo(
    () =>
      details
        ? [
            {
              icon: <ThemedIcon icon={CoinHandIcon} customColor={theme.v2.icon.primary} />,
              subText: textTranslation('portfolio.pricePerShare'),
              text: details.pricePerShare,
            },
            {
              icon: <ThemedIcon icon={CoinUnbrokenIcon} customStrokeColor={theme.v2.icon.primary} />,
              subText: textTranslation('portfolio.quantity'),
              text: formatAmountWithoutDecimals(details.sharesBought),
            },
            {
              icon: <ThemedIcon icon={UserProfileIcon} customStrokeColor={theme.v2.icon.primary} />,
              subText: textTranslation('global.smartShareCode'),
              text: details.smartShareCode,
            },
          ]
        : [],
    [details]
  );

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoadingDetails}
      showError={true}
      isError={isErrorDetails}
      textTranslation={textTranslation}
      onTryAgain={async () => await refetchDetails()}
      showEmptyState={true}
      isEmptyState={!details}
      customEmptyStateComponent={
        <DataStatusPage
          icon={
            <ThemedIcon
              icon={BarChartWithoutBorder}
              customStrokeColor={theme.v2.text.information}
              customColor={theme.v2.surface.informationLight}
              size={'full'}
            />
          }
          title={'portfolio.noShares'}
          content={'portfolio.noSharesPurchased'}
          buttonText={'portfolio.startInvesting'}
          action={() => navigate(routes.offering.smartShares.root)}
          additionalContent={'portfolio.noSharesFollow'}
          iconWidth={'8.5rem'}
          iconHeight={'8.5rem'}
        />
      }
    >
      <AssetDetails
        heading={textTranslation('portfolio.assetDetails')}
        transcationId={details?.transactionId ?? ''}
        productType={ProductType.ACTIONS}
        amount={`${details?.investedAmount}`}
        date={mapDate(details?.investmentDate || '')}
        items={itemsData}
        documents={details?.documents || []}
        assetType={AssetType.PORTFOLIO}
      />
    </PageStateContainer>
  );
};
