/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AdminNftCollectionApi } from './services/AdminNftCollectionApi';
import { ApiApi } from './services/ApiApi';
import { AssetsApi } from './services/AssetsApi';
import { AuthApi } from './services/AuthApi';
import { AvailabilityOptionApi } from './services/AvailabilityOptionApi';
import { BondsApi } from './services/BondsApi';
import { BondTransactionsApi } from './services/BondTransactionsApi';
import { BusinessFocusApi } from './services/BusinessFocusApi';
import { CalculationsApi } from './services/CalculationsApi';
import { CaseApi } from './services/CaseApi';
import { ClientPortfolioApi } from './services/ClientPortfolioApi';
import { ClientTransactionApi } from './services/ClientTransactionApi';
import { ContentfulApi } from './services/ContentfulApi';
import { CountriesApi } from './services/CountriesApi';
import { CouponPaidOptionApi } from './services/CouponPaidOptionApi';
import { CouponsApi } from './services/CouponsApi';
import { CurrenciesApi } from './services/CurrenciesApi';
import { EndUserRoleOptionApi } from './services/EndUserRoleOptionApi';
import { EventsApi } from './services/EventsApi';
import { LegalFormApi } from './services/LegalFormApi';
import { NewslettersApi } from './services/NewslettersApi';
import { NftApi } from './services/NftApi';
import { NftAdminApi } from './services/NftAdminApi';
import { NftAssetsForAdminApi } from './services/NftAssetsForAdminApi';
import { NftCollectionApi } from './services/NftCollectionApi';
import { NftCollectionClientApi } from './services/NftCollectionClientApi';
import { NftCollectionCoreApi } from './services/NftCollectionCoreApi';
import { NftCollectionsCreatorClientApi } from './services/NftCollectionsCreatorClientApi';
import { NftProductApi } from './services/NftProductApi';
import { NftProductCoreApi } from './services/NftProductCoreApi';
import { NfTsClientApi } from './services/NfTsClientApi';
import { NftTransactionsApi } from './services/NftTransactionsApi';
import { NotificationsApi } from './services/NotificationsApi';
import { OrderAdminApi } from './services/OrderAdminApi';
import { PaymentApi } from './services/PaymentApi';
import { PortfolioApi } from './services/PortfolioApi';
import { ProductApi } from './services/ProductApi';
import { RatingApi } from './services/RatingApi';
import { RegionsApi } from './services/RegionsApi';
import { RoundOptionApi } from './services/RoundOptionApi';
import { SharesApi } from './services/SharesApi';
import { ShareTransactionApi } from './services/ShareTransactionApi';
import { ShareTypeApi } from './services/ShareTypeApi';
import { TenantApi } from './services/TenantApi';
import { TransactionsApi } from './services/TransactionsApi';
import { TwilioApi } from './services/TwilioApi';
import { UserDeliveryAddressesApi } from './services/UserDeliveryAddressesApi';
import { UsersApi } from './services/UsersApi';
import { UsersSharesApi } from './services/UsersSharesApi';
import { VatApi } from './services/VatApi';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class MetaswissApi {

  public readonly adminNftCollection: AdminNftCollectionApi;
  public readonly api: ApiApi;
  public readonly assets: AssetsApi;
  public readonly auth: AuthApi;
  public readonly availabilityOption: AvailabilityOptionApi;
  public readonly bonds: BondsApi;
  public readonly bondTransactions: BondTransactionsApi;
  public readonly businessFocus: BusinessFocusApi;
  public readonly calculations: CalculationsApi;
  public readonly case: CaseApi;
  public readonly clientPortfolio: ClientPortfolioApi;
  public readonly clientTransaction: ClientTransactionApi;
  public readonly contentful: ContentfulApi;
  public readonly countries: CountriesApi;
  public readonly couponPaidOption: CouponPaidOptionApi;
  public readonly coupons: CouponsApi;
  public readonly currencies: CurrenciesApi;
  public readonly endUserRoleOption: EndUserRoleOptionApi;
  public readonly events: EventsApi;
  public readonly legalForm: LegalFormApi;
  public readonly newsletters: NewslettersApi;
  public readonly nft: NftApi;
  public readonly nftAdmin: NftAdminApi;
  public readonly nftAssetsForAdmin: NftAssetsForAdminApi;
  public readonly nftCollection: NftCollectionApi;
  public readonly nftCollectionClient: NftCollectionClientApi;
  public readonly nftCollectionCore: NftCollectionCoreApi;
  public readonly nftCollectionsCreatorClient: NftCollectionsCreatorClientApi;
  public readonly nftProduct: NftProductApi;
  public readonly nftProductCore: NftProductCoreApi;
  public readonly nfTsClient: NfTsClientApi;
  public readonly nftTransactions: NftTransactionsApi;
  public readonly notifications: NotificationsApi;
  public readonly orderAdmin: OrderAdminApi;
  public readonly payment: PaymentApi;
  public readonly portfolio: PortfolioApi;
  public readonly product: ProductApi;
  public readonly rating: RatingApi;
  public readonly regions: RegionsApi;
  public readonly roundOption: RoundOptionApi;
  public readonly shares: SharesApi;
  public readonly shareTransaction: ShareTransactionApi;
  public readonly shareType: ShareTypeApi;
  public readonly tenant: TenantApi;
  public readonly transactions: TransactionsApi;
  public readonly twilio: TwilioApi;
  public readonly userDeliveryAddresses: UserDeliveryAddressesApi;
  public readonly users: UsersApi;
  public readonly usersShares: UsersSharesApi;
  public readonly vat: VatApi;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '5',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.adminNftCollection = new AdminNftCollectionApi(this.request);
    this.api = new ApiApi(this.request);
    this.assets = new AssetsApi(this.request);
    this.auth = new AuthApi(this.request);
    this.availabilityOption = new AvailabilityOptionApi(this.request);
    this.bonds = new BondsApi(this.request);
    this.bondTransactions = new BondTransactionsApi(this.request);
    this.businessFocus = new BusinessFocusApi(this.request);
    this.calculations = new CalculationsApi(this.request);
    this.case = new CaseApi(this.request);
    this.clientPortfolio = new ClientPortfolioApi(this.request);
    this.clientTransaction = new ClientTransactionApi(this.request);
    this.contentful = new ContentfulApi(this.request);
    this.countries = new CountriesApi(this.request);
    this.couponPaidOption = new CouponPaidOptionApi(this.request);
    this.coupons = new CouponsApi(this.request);
    this.currencies = new CurrenciesApi(this.request);
    this.endUserRoleOption = new EndUserRoleOptionApi(this.request);
    this.events = new EventsApi(this.request);
    this.legalForm = new LegalFormApi(this.request);
    this.newsletters = new NewslettersApi(this.request);
    this.nft = new NftApi(this.request);
    this.nftAdmin = new NftAdminApi(this.request);
    this.nftAssetsForAdmin = new NftAssetsForAdminApi(this.request);
    this.nftCollection = new NftCollectionApi(this.request);
    this.nftCollectionClient = new NftCollectionClientApi(this.request);
    this.nftCollectionCore = new NftCollectionCoreApi(this.request);
    this.nftCollectionsCreatorClient = new NftCollectionsCreatorClientApi(this.request);
    this.nftProduct = new NftProductApi(this.request);
    this.nftProductCore = new NftProductCoreApi(this.request);
    this.nfTsClient = new NfTsClientApi(this.request);
    this.nftTransactions = new NftTransactionsApi(this.request);
    this.notifications = new NotificationsApi(this.request);
    this.orderAdmin = new OrderAdminApi(this.request);
    this.payment = new PaymentApi(this.request);
    this.portfolio = new PortfolioApi(this.request);
    this.product = new ProductApi(this.request);
    this.rating = new RatingApi(this.request);
    this.regions = new RegionsApi(this.request);
    this.roundOption = new RoundOptionApi(this.request);
    this.shares = new SharesApi(this.request);
    this.shareTransaction = new ShareTransactionApi(this.request);
    this.shareType = new ShareTypeApi(this.request);
    this.tenant = new TenantApi(this.request);
    this.transactions = new TransactionsApi(this.request);
    this.twilio = new TwilioApi(this.request);
    this.userDeliveryAddresses = new UserDeliveryAddressesApi(this.request);
    this.users = new UsersApi(this.request);
    this.usersShares = new UsersSharesApi(this.request);
    this.vat = new VatApi(this.request);
  }
}

