import { styled } from 'styled-components';

import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';
import { ProductsWrapperV2 } from '../../organism/list-card-item/listCardItem.styles';

export const IssuerUserCardContainer = styled.div`
  position: relative;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
`;

export const AvatarContextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
`;

export const ContextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;

  ${TextTemplate} {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const ChipsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  transition: transform 0.2s ease-out;

  ${IssuerUserCardContainer}:hover & {
    transform: translateX(0.5rem);
  }
`;

export const LeftContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
`;

export const LayouRowWrapper = styled.div`
  display: flex;
  gap: 1rem;
  ${ProductsWrapperV2} {
    &:not(:first-child) {
      border-left: 1px solid ${({ theme }) => theme.v2.border.primary};
      padding-left: 1rem;
    }
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
