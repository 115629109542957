import { formatAmount, formatAmountWithDot } from '@metaswiss/lib';
import {
  ArrowChartIcon,
  BarChartNoBorderIcon,
  CollectibleIcon,
  Heading,
  Text,
  ThemedIcon,
  TrendUp01Icon,
} from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../../api/msApi';
import { TenantPermissions } from '../../../../../../enums/tenantPermissions';
import { AppState, useAppState } from '../../../../../../global-state/zustand';
import { usePermissions } from '../../../../../../hooks/use-permissions/usePermissions';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../../router/routes';
import { BuyCard } from '../buy-card/BuyCard';

import {
  BuyCardsContainerWrapper,
  NoProductsIconWrapper,
  NoProductsWrapper,
  NoProuctsHeadingWrapper,
} from './buyCardsContainer.styles';

export const BuyCardsContainer = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const permissions = usePermissions();

  const currency = useAppState((state: AppState) => state.currency);
  const navigate = useNavigate();

  const { data: bondOffering } = useQuery({
    queryKey: ['bondOffering'],
    queryFn: () => api.bonds.getTenantOffering(),
  });

  const { data: shareOffering } = useQuery({
    queryKey: ['shareOffering'],
    queryFn: () => api.shares.getTenantOffering(),
  });

  const { data: nftOffering } = useQuery({
    queryKey: ['nftOffering'],
    queryFn: () => api.nftCollectionCore.getTenantOffering(),
  });

  if (!nftOffering && !shareOffering && !bondOffering) {
    return (
      <NoProductsWrapper>
        <NoProductsIconWrapper>
          <ThemedIcon icon={TrendUp01Icon} customStrokeColor={theme.v2.icon.primary} size={'midLarge'} />
        </NoProductsIconWrapper>
        <NoProuctsHeadingWrapper>
          <Heading headingType={'h3'} fontWeight={'bold'}>
            {textTranslation('home.noProducts')}
          </Heading>
        </NoProuctsHeadingWrapper>
        <Text>{textTranslation('home.currentlyNoProductsAvailable')}</Text>
      </NoProductsWrapper>
    );
  }

  return (
    <BuyCardsContainerWrapper>
      {permissions[TenantPermissions.NFT] && nftOffering && (
        <BuyCard
          icon={CollectibleIcon}
          title={formatAmount(nftOffering.offer, currency?.decimals, currency?.rate, currency?.currencyCode)}
          onClick={() => navigate(routes.offering.smartCollectibles.root)}
          subtitle={textTranslation('home.pricePerCollectible')}
          buttonText={textTranslation('home.buyCollectibles')}
          descriptionCount={nftOffering.avaliableNftCollections}
          description={textTranslation('home.collectiblesAvailable')}
        />
      )}
      {permissions[TenantPermissions.SHARE] && shareOffering && (
        <BuyCard
          icon={BarChartNoBorderIcon}
          title={formatAmount(shareOffering.offer, currency?.decimals, currency?.rate, currency?.currencyCode)}
          onClick={() => navigate(routes.offering.smartShares.root)}
          subtitle={textTranslation('home.pricePerShare')}
          buttonText={textTranslation('home.buyShare')}
          descriptionCount={formatAmountWithDot(shareOffering.availableForSale)}
          description={textTranslation('home.smartShareAvailable')}
        />
      )}
      {permissions[TenantPermissions.BOND] && bondOffering && (
        <BuyCard
          icon={ArrowChartIcon}
          title={`${bondOffering?.offer ?? 0}%`}
          onClick={() => navigate(routes.offering.smartBonds.root)}
          subtitle={textTranslation('home.interestBond')}
          buttonText={textTranslation('home.buyBond')}
          descriptionCount={formatAmountWithDot(bondOffering?.availableForSale ?? 0)}
          description={textTranslation('home.smartBondAvailable')}
        />
      )}
    </BuyCardsContainerWrapper>
  );
};
