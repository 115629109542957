import { BlurredBackground, Button, Heading, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useTenantConfig } from '../../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes';

import {
  ButtonWrapper,
  ContentWrapper,
  ErrorImageContainer,
  HeadingWrapper,
  LogoContainer,
  LogoWrapper,
  Wrapper,
} from './error404.styles';

export const Error404: FC = () => {
  const { textTranslation } = useTextTranslation();
  const tenantConfig = useTenantConfig();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <BlurredBackground palette={'primary'} customBlurBackground={tenantConfig.blurBackground}>
      <Wrapper>
        <ContentWrapper>
          <LogoContainer>
            <LogoWrapper src={tenantConfig.logoLarge} />
          </LogoContainer>
          <ErrorImageContainer>
            <img src={tenantConfig.error404Icon} alt="error-404" />
          </ErrorImageContainer>
          <HeadingWrapper>
            <Heading fontWeight="bold" headingType="h3" color={theme.v2.text.headingPrimary}>
              {textTranslation('error.pageNotFound')}
            </Heading>
          </HeadingWrapper>
          <Text fontSize="base" fontWeight="reg" color={theme.v2.text.bodyPrimary}>
            {textTranslation('error.weApologize')}
          </Text>
        </ContentWrapper>
        <ButtonWrapper>
          <Button text={textTranslation('error.backHome')} fill onClick={() => navigate(routes.home.root)} />
        </ButtonWrapper>
      </Wrapper>
    </BlurredBackground>
  );
};
