import { Button, SideBanner, Stepper, Text, TextLink } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../api/msApi';
import LanguagePicker from '../../components/language-picker/LanguagePicker';
import { ApiResource } from '../../enums/resource.enum';
import { AppState, useAppState } from '../../global-state/zustand';
import { useCountries } from '../../hooks/use-countries/useCountries';
import { useRouteValidationCheck } from '../../hooks/use-route-validation-check/useRouteValidationCheck';
import { useTenantConfig } from '../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { AnimationButtonWrapper } from '../../pages/public/shared/animations/AnimationButtonWrapper';
import { actionTextVariants, buttonTransition } from '../../pages/public/shared/animations/animations';
import { useAuthContext } from '../../pages/public/shared/auth-context/authContext';
import { AuthFooter } from '../../pages/public/shared/auth-footer/AuthFooter';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

import {
  ActionTextWrapper,
  ButtonsContainer,
  ContentContainer,
  ContentFooter,
  ContentHeader,
  InnerWrapper,
  LogoContainer,
  MainContentContainer,
  PublicLayoutContainer,
  PublicLayoutMain,
  StepperWrapper,
  Wrapper,
} from './styles/publicLayout.styles';

export const PublicLayout: FC = () => {
  const { textTranslation, currentLanguage } = useTextTranslation();
  const tenantConfig = useTenantConfig();
  const { pathname } = useLocation();

  const [isScrollbarActive, setIsScrollbarActive] = useState<boolean>(false);

  const publicLayoutMainRef = useRef<HTMLDivElement | null>(null);
  const setVat = useAppState((state: AppState) => state.setVat);
  const theme = useTheme();

  useCountries();
  useRouteValidationCheck();

  const { data: vat, isSuccess } = useQuery({
    queryKey: getQueryKey(ApiResource.VAT),
    queryFn: () => api.vat.getVat(),
  });

  const handleMainContentHeight = () => {
    if (publicLayoutMainRef.current) {
      setIsScrollbarActive(publicLayoutMainRef.current.scrollHeight > publicLayoutMainRef.current.clientHeight);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setVat(vat.value);
    }
  }, [isSuccess, vat, setVat]);

  const {
    activeRegistrationStep,
    maxRegistrationStep,
    loading,
    isValid,
    config,
    clearAuthContext,
    setAnimationDirection,
  } = useAuthContext((state) => state);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && config?.nextButton?.onClick) {
        event.preventDefault();
        if (isValid) {
          config.nextButton.onClick();
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [config.nextButton, clearAuthContext, isValid]);

  useEffect(() => {
    // We need to use setTimeout in this case to assure the scrollTo function will be called after the DOM update,
    // preventing potential issues. (ensure function will be called with the most up to date DOM elements)
    setTimeout(() => {
      if (publicLayoutMainRef.current) {
        handleMainContentHeight();
        publicLayoutMainRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, 0);
  }, [pathname, activeRegistrationStep]);

  useEffect(() => {
    window.addEventListener('resize', handleMainContentHeight);

    return () => {
      window.removeEventListener('resize', handleMainContentHeight);
    };
  }, []);

  return (
    <PublicLayoutContainer>
      <SideBanner
        imgUrl={tenantConfig.loginBannerUrl}
        text={tenantConfig[`publicTitle_${currentLanguage}`]}
        imageAlignment={tenantConfig.sideBannerImageAlignment}
      />
      <PublicLayoutMain ref={publicLayoutMainRef}>
        <LanguagePicker />
        <MainContentContainer $isScrollBarActive={isScrollbarActive}>
          <Wrapper>
            <InnerWrapper $page={config.wrapperStyle}>
              <ContentContainer $page={config.containerStyle}>
                <ContentHeader $page={config.containerStyle}>
                  {!config.removeLogo && <LogoContainer src={tenantConfig.logoSmall} />}
                  <AnimatePresence initial={false}>
                    <Outlet />
                  </AnimatePresence>
                </ContentHeader>
                <ContentFooter>
                  {config.showStepper && (
                    <StepperWrapper key="stepper">
                      <Stepper currentStep={activeRegistrationStep} numberOfSteps={maxRegistrationStep} />
                    </StepperWrapper>
                  )}
                  <AnimatePresence mode="popLayout">
                    <ButtonsContainer key="buttons">
                      {config.backButton && (
                        <AnimationButtonWrapper key="backButton">
                          <Button
                            text={textTranslation(config.backButton.text)}
                            type="button"
                            variant="outlined"
                            color="neutral"
                            fill
                            onClick={() => {
                              setAnimationDirection(config.backButton?.animationDirection || 'backward');

                              setTimeout(() => {
                                config.backButton?.onClick();
                              }, 0);
                            }}
                          />
                        </AnimationButtonWrapper>
                      )}
                      {config.nextButton && (
                        <AnimationButtonWrapper key="nextButton">
                          <Button
                            text={textTranslation(config.nextButton?.text)}
                            onClick={() => {
                              setAnimationDirection(config.nextButton?.animationDirection || 'forward');

                              setTimeout(() => {
                                config.nextButton?.onClick?.();
                              }, 0);
                            }}
                            loading={loading}
                            type={config.nextButton?.type}
                            disabled={!isValid}
                            key={config.formId || config.nextButton?.text}
                            fill
                          />
                        </AnimationButtonWrapper>
                      )}
                    </ButtonsContainer>

                    {config.actionText && (
                      <ActionTextWrapper
                        key="actionText"
                        variants={actionTextVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={buttonTransition}
                        layout
                      >
                        <Text color={theme.v2.text.bodyPrimary}>{textTranslation(config.actionText.text)}</Text>
                        <TextLink
                          padding={'0.25rem 0.5rem'}
                          onClick={(e) => {
                            if (config.actionText?.animationDirection) {
                              setAnimationDirection(config.actionText.animationDirection);
                            }

                            setTimeout(() => {
                              config.actionText?.onClick?.(e);
                            }, 0);
                          }}
                          disabled={config.actionText.disabled}
                        >
                          {typeof config.actionText.linkText === 'string'
                            ? textTranslation(config.actionText.linkText)
                            : config.actionText.linkText()}
                        </TextLink>
                      </ActionTextWrapper>
                    )}
                  </AnimatePresence>
                </ContentFooter>
              </ContentContainer>
            </InnerWrapper>
            <AuthFooter />
          </Wrapper>
        </MainContentContainer>
      </PublicLayoutMain>
    </PublicLayoutContainer>
  );
};
