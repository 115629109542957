import styled from 'styled-components';

export const ClientContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.surface.action1};
  background-color: ${({ theme }) => theme.colors.neutrals.hue0};
  height: 4.75rem; // 2.75rem is height of the button filters and 2 rem is the padding
`;

export const TitleAndBreadcrumb = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const HeaderContent = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const InputWrapper = styled.div`
  height: 2.5rem;
  width: 21.25rem;
`;

export const AvatarWrapper = styled.div`
  position: relative;
`;
export const IconsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Divider = styled.div`
  height: 1.5rem;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.neutrals.hue200};
`;
