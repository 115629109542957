import {
  AssetResponse,
  CorporateUserFullResponse,
  CorporateUserResponse,
  PrivateUserFullResponse,
  PrivateUserResponse,
} from '@metaswiss/api';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { api } from '../../../api/msApi';
import { AssignedUserAssetEnum } from '../../../enums/assignedUserAssetEnum';
import { ApiResource } from '../../../enums/resource.enum';
import { UserRole } from '../../../enums/userRole';
import { AppState, useAppState } from '../../../global-state/zustand';
import { useBusinesses } from '../../../hooks/use-businesses/useBusinesses';
import { useCountries } from '../../../hooks/use-countries/useCountries';
import { useLegalForm } from '../../../hooks/use-legal-form/useLegalForm';
import { useSetRouteNavigation } from '../../../hooks/use-set-route-navigation/useSetRouteNavigation';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes';
import { defaultUser } from '../../../shared/helpers/defaultUser';
import { findAssetByType } from '../../../shared/helpers/findAssetByType.helper';
import { getIsUserPictureUploaded } from '../../../shared/helpers/getIsUserPictureUploaded';
import { getQueryKey } from '../../../shared/helpers/getQueryKey.helper';

import { accountTabs } from './shared/values/accountTabs';

export type PrivateFullResponse = keyof PrivateUserFullResponse | keyof PrivateUserResponse;
export type CorporateFullResponse = keyof CorporateUserFullResponse | keyof CorporateUserResponse;

export const Account = () => {
  const { textTranslation } = useTextTranslation();
  const { pathname } = useLocation();

  const user = useAppState((state: AppState) => state.user) || defaultUser;
  const { setUser } = useAppState((state) => state);

  useBusinesses();
  useLegalForm();
  useCountries();

  const { data: privateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.PRIVATE_USER, user?.id),
    queryFn: () => api.users.getPrivateUser(user.id),
    enabled: !!user?.id && user?.role === UserRole.PRIVATE,
  });

  const { data: corporateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.CORPORATE_USER, user.id),
    queryFn: () => api.users.getCorporateUser(user.id),
    enabled: !!user?.id && user.role === UserRole.CORPORATE,
  });

  const { data: assets } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET, user.id),
    queryFn: () => {
      return user?.role === UserRole.PRIVATE
        ? api.users.getPrivateUserAssets(user.id)
        : api.users.getCorporateUserAssets(user.id);
    },
    enabled: !!user?.role && !!user?.id,
  });

  useEffect(() => {
    if (privateUser) {
      setUser(privateUser);
    }
    if (corporateUser) {
      setUser(corporateUser);
    }
  }, [setUser, privateUser, corporateUser]);

  const isPictureUploaded = getIsUserPictureUploaded(assets);

  useQuery({
    queryKey: getQueryKey(ApiResource.ASSIGNED_URL, findAssetByType(assets, AssignedUserAssetEnum.PICTURE)?.id),
    queryFn: () => {
      const picture = findAssetByType(assets, AssignedUserAssetEnum.PICTURE);
      return api.assets.getS3SignedAssignedUrl({ assetId: picture?.id || '' });
    },
    enabled: isPictureUploaded,
  });

  const hasEmptyField = (fieldsToCheck: PrivateFullResponse[]) => {
    const data = { ...privateUser, ...privateUser?.privateUser };
    return fieldsToCheck.some((field: PrivateFullResponse) => data[field] == null || data[field] === '');
  };

  const hasCorporateUserEmptyField = (fieldsToCheck: CorporateFullResponse[]) => {
    const data = { ...corporateUser, ...corporateUser?.corporateUser };
    return fieldsToCheck.some((field: CorporateFullResponse) => data[field] === null || data[field] === '');
  };

  const hasDocuments = useMemo(() => {
    type DocumentsType = { [key: string]: boolean | undefined };

    const documents: DocumentsType = {};

    assets?.forEach((asset: AssetResponse) => {
      documents[asset.type] = true;
    });

    const identityProof =
      (documents[AssignedUserAssetEnum.ID_FRONT] && documents[AssignedUserAssetEnum.ID_BACK]) ||
      documents[AssignedUserAssetEnum.PASSPORT];

    if (user.role === UserRole.PRIVATE) {
      return documents[AssignedUserAssetEnum.PROOF_OF_DOMICILE] && identityProof;
    }
    return (
      documents[AssignedUserAssetEnum.COMMERCIAL_REGISTER] &&
      documents[AssignedUserAssetEnum.SIGNATURE] &&
      documents[AssignedUserAssetEnum.PROOF_OF_DOMICILE]
    );
  }, [assets, user.role]);

  const checkEmptyField = (
    tabName: string,
    privateUser?: PrivateUserFullResponse,
    corporateUser?: CorporateUserFullResponse
  ): boolean => {
    if (privateUser) {
      switch (tabName) {
        case 'Profile':
          return hasEmptyField(['birthDate', 'phoneNumber', 'citizenship']);
        case 'Address':
          return hasEmptyField(['address', 'country', 'city', 'zipCode']);
        case 'Verification':
          return !hasDocuments;
        default:
          return false;
      }
    }
    if (corporateUser) {
      switch (tabName) {
        case 'Profile':
          return hasCorporateUserEmptyField(['dateFounded', 'phoneNumber', 'businessFocus', 'legalForm']);
        case 'Address':
          return hasCorporateUserEmptyField(['address', 'country', 'city', 'zipCode']);
        case 'Verification':
          return !hasDocuments;
        default:
          return false;
      }
    }
    return false;
  };

  const accountTabsWithTranslation = useMemo(
    () =>
      accountTabs.map((tab) => {
        const hasEmptyField = checkEmptyField(tab.label, privateUser, corporateUser);

        return { ...tab, label: textTranslation(`account.${tab.value}`), indicator: hasEmptyField };
      }),
    [privateUser, corporateUser, assets]
  );

  const isAccountDeepNestedRoute = useMemo(() => pathname.split('/').length > 3, [pathname]);

  useSetRouteNavigation(accountTabsWithTranslation, !isAccountDeepNestedRoute ? routes.account.overview : undefined);

  return <Outlet />;
};
