import { NftCollectionResponse, NftCollectionV5Response } from '@metaswiss/api';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { api } from '../../api/msApi';
import { ApiResource } from '../../enums/resource.enum';
import { Response } from '../../pages/protected/offering/smart-collectibles/single-collection/SingleCollection';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

export const useGetCollectionByParams = () => {
  const { collectionId: id = '' } = useParams();
  const [regionError, setRegionError] = useState<boolean>(false);

  const {
    data: nftCollections,
    isLoading: isLoadingNftCollection,
    isError: isErrorNftCollection,
    refetch: refetchNftCollection,
    error,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION),
    queryFn: () => api.nftCollection.getAllNftCollections(),
    enabled: !id,
    retry: false,
  });

  const {
    data: collectionById,
    isLoading: isLoadingCollection,
    isError: isErrorCollection,
    refetch: refetchCollection,
    error: collectionError,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_SINGLE_COLLECTION, id),
    queryFn: () => api.nftCollection.getNftCollectionById(id),
    enabled: !!id,
    retry: false,
  });

  const collection = useMemo(() => {
    if (id) {
      return collectionById as NftCollectionV5Response;
    }
    return (nftCollections?.items[0] as unknown as NftCollectionResponse) || {};
  }, [nftCollections?.items, collectionById, id]);

  useEffect(() => {
    const response = (error || collectionError) as Response;
    if (response?.body?.code === 405) {
      setRegionError(true);
    }
  }, [error, collectionError]);

  const collectionId = id || collection.id;

  return {
    id: collectionId,
    collection,
    isEmpty: nftCollections?.items.length === 0,
    isLoading: id ? isLoadingCollection : isLoadingNftCollection,
    isError: id ? isErrorCollection : isErrorNftCollection,
    refetch: async () => {
      await Promise.all([refetchNftCollection(), refetchCollection()]);
    },
    regionError,
  };
};
