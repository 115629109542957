import { FC } from 'react';
import { useTheme } from 'styled-components';

import { Text, ThemedIcon } from '../../../components';
import { TickCheckedCircleIcon } from '../../../iconography';

import { ZigZagPath } from './ZigZagPath';
import {
  HeadingContentWrapper,
  PathContainer,
  PaymentBillBody,
  PaymentBillContainer,
  SuccessfullPaymentIconWrapper,
} from './paymentBill.styles';
import { PaymentBillProps } from './types';

export const PaymentBill: FC<PaymentBillProps> = ({
  title,
  billInfoComponent,
  children,
  isPaymentSuccessful = false,
}) => {
  const theme = useTheme();

  return (
    <PaymentBillContainer>
      {isPaymentSuccessful && (
        <SuccessfullPaymentIconWrapper>
          <ThemedIcon size={'large'} icon={TickCheckedCircleIcon} customColor={theme.v2.icon.primary} />
        </SuccessfullPaymentIconWrapper>
      )}
      <PaymentBillBody>
        <HeadingContentWrapper>
          <Text color={theme.v2.text.headingPrimary} fontSize={'lg'} lineHeight={'h4'} fontWeight={'bold'}>
            {title}
          </Text>
          {billInfoComponent}
        </HeadingContentWrapper>
        {children}
      </PaymentBillBody>
      <PathContainer>
        <ZigZagPath backgroundColor={theme.v2.surface.primary} />
      </PathContainer>
    </PaymentBillContainer>
  );
};
