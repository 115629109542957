import { styled } from 'styled-components';

import { ThemedIconContainer } from '../../../../components/molecules/theme-icon/styles/iconContainer';
import { RemSize } from '../../../../theme';

export const CircleIconWrapper = styled.div<{ $size: RemSize }>`
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  background-color: ${({ theme }) => theme.v2.surface.tertiary};
  border-radius: ${({ theme }) => theme.v2.radius.full};
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.v2.surface.informationLight};

    ${ThemedIconContainer} {
      path {
        stroke: ${({ theme }) => theme.v2.icon.primary};
      }
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.v2.surface.tertiary};

    ${ThemedIconContainer} {
      path {
        stroke: ${({ theme }) => theme.v2.icon.default};
      }
    }
  }
`;
