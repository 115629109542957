import styled from 'styled-components';

import { TextTemplate } from '../../text';

export const EllipsisContainer = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  justify-content: start;
  align-items: center;
  flex: 1;
  &:first-child {
    ${TextTemplate} {
      color: ${({ $isDisabled }) => ($isDisabled ? '#757da1' : 'inherit')};
    }
  }
`;
