import { RemSize } from '@metaswiss/ui-kit/src/theme';
import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
`;

export const TooltipHolder = styled.div<{
  $leftPosition: RemSize;
  $topPosition: RemSize;
  $backgroundColor?: string;
}>`
  position: absolute;
  top: ${({ $topPosition }) => $topPosition};
  left: ${({ $leftPosition }) => $leftPosition};
  transform: translateY(-50%);
  z-index: 3;
  max-width: fit-content;

  &::before {
    content: '';
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    top: 90.7%;
    left: 6%;
    transform: translate(-30%, -50%) rotate(-135deg);
    z-index: -1;
    background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.v2.surface.tertiary};
    border-radius: 0.1rem;
  }
`;

export const TooltipContent = styled.div<{ $backgroundColor?: string }>`
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.v2.surface.tertiary};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const HoverIconWrapper = styled.div<{ $leftPosition: RemSize; $topPosition: RemSize }>`
  position: absolute;
  opacity: 0;
  z-index: 2;
  top: ${({ $topPosition }) => $topPosition};
  left: ${({ $leftPosition }) => $leftPosition};
  background-color: red;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
`;
