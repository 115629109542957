/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TenantConfigResponse } from '../models/TenantConfigResponse';
import type { TenantThemeAndPermissionsResponse } from '../models/TenantThemeAndPermissionsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TenantApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns TenantConfigResponse Tenant platform config
   * @throws ApiError
   */
  public getConfig(): CancelablePromise<TenantConfigResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/tenant-platform/config',
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns TenantThemeAndPermissionsResponse Tenant platform theme
   * @throws ApiError
   */
  public getTheme(): CancelablePromise<TenantThemeAndPermissionsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/tenant-platform/theme',
      errors: {
        500: `Bad Request`,
      },
    });
  }

}
