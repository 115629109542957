import { TenantConfigResponse } from '@metaswiss/api';
import { useQueryClient } from '@tanstack/react-query';

import { ApiResource } from '../../enums/resource.enum';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

export const useTenantConfig = () => {
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<TenantConfigResponse>(getQueryKey(ApiResource.TENANT_CONFIG));

  return queryData?.config || {};
};
