import { motion } from 'framer-motion';
import styled from 'styled-components';

import { breakpoints } from '../../../breakpoints';

export const AppWizardContainer = styled.div`
  width: 44.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  overflow: hidden;
  @media ${breakpoints.desktopSm} {
    width: 37.5rem;
  }

  @media ${breakpoints.desktop2K} {
    height: 51.25rem;
  }
`;

export const AppWizardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AppWizardHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const AppWizardHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ButtonWrapper = styled(motion.div)`
  display: none;
  @media ${breakpoints.desktopSm} {
    margin-top: 1rem;
    display: block;
  }
`;

export const IconWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
export const ChildrenWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  min-height: 11.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
