import { ToastQueueProvider } from '@metaswiss/lib';
import { breakpointValues, InitialErrorDataStatus, InitialLoader, MobileAppScreen, Theme } from '@metaswiss/ui-kit';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';

import { api } from './api/msApi';
import { ApiResource } from './enums/resource.enum';
import { AppState, useAppState } from './global-state/zustand';
import { GlobalStyles } from './globalStyles';
import { useBrowserLanguage } from './hooks/use-browser-language/useBrowserLanguage';
import { useTextTranslation } from './hooks/use-text-translation/useTextTranslation';
import { router, routerMaintenance } from './router/Router';
import { routes } from './router/routes';
import { getQueryKey } from './shared/helpers/getQueryKey.helper';
import { ThemeProvider } from './shared/theme/ThemeProvider';

function AppContent() {
  const { setTranslationLanguage, textTranslation } = useTextTranslation();
  const { isMaintenanceMode } = useAppState((state) => state);
  const language = useBrowserLanguage();

  const currentUrl = window.location.pathname;
  const isVerifyRoute = currentUrl.includes(routes.verify);

  const [isMobileSizedScreen, setIsMobileSizedScreen] = useState<boolean>(
    window.innerWidth < breakpointValues.tabletSm && !isVerifyRoute
  );

  const {
    data: tenantData,
    isLoading: isLoadingTenantData,
    isError: isErrorTenantData,
    refetch: refetchTenantData,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.TENANT_THEME),
    queryFn: () => api.tenant.getTheme(),
    staleTime: Infinity,
  });

  const {
    data: tenantConfig,
    isLoading: isLoadingTenantConfig,
    isError: isErrorTenantConfig,
    refetch: refetchTenantConfig,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.TENANT_CONFIG),
    queryFn: () => api.tenant.getConfig(),
    staleTime: Infinity,
  });

  useEffect(() => {
    setTranslationLanguage(language);
  }, [language, setTranslationLanguage]);

  const { redirectingFromVerifyViaMobile, setRedirectingFromVerifyViaMobile } = useAppState((state: AppState) => state);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileSizedScreen(window.innerWidth < breakpointValues.tabletSm && !isVerifyRoute ? true : false);
      if (window.innerWidth < breakpointValues.tabletSm && !isVerifyRoute) {
        setRedirectingFromVerifyViaMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isLoadingTenantData || isLoadingTenantConfig) {
    return <InitialLoader />;
  }

  if (isErrorTenantData || isErrorTenantConfig) {
    return (
      <InitialErrorDataStatus
        title={textTranslation('global.somethingWentWrong')}
        description={textTranslation('global.anErrorOccurred')}
        buttonText={textTranslation('global.tryAgain')}
        onTryAgain={() => Promise.all([refetchTenantData(), refetchTenantConfig()])}
      />
    );
  }

  return (
    <ThemeProvider theme={tenantData.theme as Theme}>
      <GlobalStyles theme={tenantData.theme} />
      <ToastQueueProvider>
        {redirectingFromVerifyViaMobile || isMobileSizedScreen ? (
          <MobileAppScreen
            headingText={textTranslation('mobileAppScreen.downloadApp')}
            descriptionText={textTranslation('mobileAppScreen.applicationIsNotOptimized')}
            logo={tenantConfig.config.logoSmall}
            googlePlayAppLink={tenantConfig.config.googlePlayAppLink}
            appStoreAppLink={tenantConfig.config.appStoreAppLink}
          />
        ) : (
          <RouterProvider router={isMaintenanceMode ? routerMaintenance : router(tenantData.permissions)} />
        )}
      </ToastQueueProvider>
    </ThemeProvider>
  );
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: Infinity } },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContent />
    </QueryClientProvider>
  );
}

export default App;
