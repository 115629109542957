import { Text, TextLink } from '@metaswiss/ui-kit';
import { useTheme } from 'styled-components';

import { useTenantConfig } from '../../../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';

import { FooterTextLinkWrapper, RegisterFooterName, RegistrationBottomWrapper } from './authFooter.styles';

export const AuthFooter = () => {
  const { textTranslation, currentLanguage } = useTextTranslation();
  const tenantConfig = useTenantConfig();
  const theme = useTheme();

  return (
    <RegistrationBottomWrapper>
      <RegisterFooterName>
        <Text fontSize="sm" lineHeight="medium" color={theme.v2.text.bodyPrimary}>
          {tenantConfig.footerCompanyName}
        </Text>
        <Text color={theme.v2.border.primaryHover}>|</Text>
        <FooterTextLinkWrapper>
          <Text fontSize="sm" lineHeight="medium" color={theme.v2.text.bodyPrimary}>
            {textTranslation('registration.craftedBy')}
          </Text>
          <TextLink
            padding={'0.25rem'}
            onClick={() => window.open(tenantConfig[`footerCompanyLink_${currentLanguage}`], '_blank')}
          >
            {tenantConfig.footerCompanyName}
          </TextLink>
        </FooterTextLinkWrapper>
      </RegisterFooterName>
    </RegistrationBottomWrapper>
  );
};
