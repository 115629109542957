import * as zod from 'zod';

import { FormNames } from '../../../../shared/forms/formNames.ts';
import { staticTranslationFunction } from '../../../../shared/forms/staticTranslationFunction.ts';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createBankInformationSchema = (_: (key: string) => string) =>
  zod.object({
    bankName: zod.string().optional(),
    bankAddress: zod.string().optional(),
    iban: zod.string().optional(),
    depotNumber: zod.string().optional(),
  });

export const schema = createBankInformationSchema(staticTranslationFunction);

export type EditBankInformationFormData = zod.infer<typeof schema>;

export const editBankInformationSchemaNames: Required<FormNames<EditBankInformationFormData>> = {
  bankName: 'bankName',
  bankAddress: 'bankAddress',
  iban: 'iban',
  depotNumber: 'depotNumber',
};
