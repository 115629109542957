import { CorporateUserFullResponse, PrivateUserFullResponse } from '@metaswiss/api';
import {
  AlertIcon,
  AlertTriangleIcon,
  Avatar,
  Chip,
  HeaderCard,
  Heading,
  PageStateContainer,
  Status,
  Text,
  IconProps,
} from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { ComponentProps, FC, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import { AssignedUserAssetEnum } from '../../../../enums/assignedUserAssetEnum';
import { ApiResource } from '../../../../enums/resource.enum';
import { UserRole } from '../../../../enums/userRole';
import { UserStatus } from '../../../../enums/userStatus.enum';
import { useAppState } from '../../../../global-state/zustand';
import { useTenantConfig } from '../../../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { defaultUser } from '../../../../shared/helpers/defaultUser';
import { findAssetByType } from '../../../../shared/helpers/findAssetByType.helper';
import { getIsUserPictureUploaded } from '../../../../shared/helpers/getIsUserPictureUploaded';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';

import { CorporateUserOverview } from './components/CorporateUserOverview';
import { PrivateUserOverview } from './components/PrivateUserOverview';
import {
  AccountStatusWrapper,
  AvatarContainer,
  DetailsContainer,
  PanelContainer,
  PanelCover,
  PanelCoverImage,
  PersonalInformationContainer,
} from './overview.styles';

export const Overview = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const tenantConfig = useTenantConfig();
  const user = useAppState((state) => state.user) || defaultUser;
  const { setUser } = useAppState((state) => state);

  const { data: assets } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET, user.id),
    queryFn: () => {
      return user?.role === UserRole.PRIVATE
        ? api.users.getPrivateUserAssets(user.id)
        : api.users.getCorporateUserAssets(user.id);
    },
    enabled: !!user?.role && !!user?.id,
  });

  const isPictureUploaded = getIsUserPictureUploaded(assets);

  const { data: imageUrl } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSIGNED_URL, findAssetByType(assets, AssignedUserAssetEnum.PICTURE)?.id),
    queryFn: () => {
      const picture = findAssetByType(assets, AssignedUserAssetEnum.PICTURE);
      return api.assets.getS3SignedAssignedUrl({ assetId: picture?.id || '' });
    },
    enabled: isPictureUploaded,
  });

  const userResource = useMemo(() => {
    return user?.role === UserRole.PRIVATE ? ApiResource.PRIVATE_USER : ApiResource.CORPORATE_USER;
  }, [user]);

  const {
    data: userData,
    isLoading,
    isError,
    refetch,
  } = useQuery<PrivateUserFullResponse | CorporateUserFullResponse>({
    queryKey: getQueryKey(userResource, user.id),
    queryFn: () => {
      return user?.role === UserRole.PRIVATE ? api.users.getPrivateUser(user.id) : api.users.getCorporateUser(user.id);
    },
    enabled: !!user?.role && !!user?.id,
  });

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData, setUser]);

  const { statusChip, chipMessageCode, userStatus, icon, statusMessage } = getStatusInformation(
    user.status as UserStatus,
    user.isFullyRegistered
  );

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      showEmptyState={false}
      isError={isError}
      showError={true}
      onTryAgain={refetch}
      textTranslation={textTranslation}
      maxWidth="44.5rem"
    >
      <PanelContainer>
        <PanelCover>
          <PanelCoverImage src={tenantConfig.accountCoverImg} />
        </PanelCover>
        <AvatarContainer>
          <Avatar
            imagePath={imageUrl?.url}
            isCursorPointer={false}
            border={`1px solid ${theme.colors.neutrals.hue100}`}
          />
        </AvatarContainer>
        <div>
          <Heading headingType="h4" fontWeight="bold">
            {user.displayName}
          </Heading>
          <Text>{user.email}</Text>
        </div>

        <Chip label={textTranslation(chipMessageCode)} status={statusChip} />
      </PanelContainer>
      <HeaderCard
        header={<Text fontWeight="bold">{textTranslation('account.accountInformation')}</Text>}
        removeBackground={false}
        height={'fit-content'}
        bodyPadding={'0rem'}
      >
        <DetailsContainer>
          {user.status === UserStatus.PENDING && (
            <AccountStatusWrapper>
              <Status status={userStatus} text={textTranslation(statusMessage)} icon={icon} />
            </AccountStatusWrapper>
          )}
          <PersonalInformationContainer>
            {user.role === UserRole.PRIVATE && <PrivateUserOverview />}
            {user.role === UserRole.CORPORATE && <CorporateUserOverview />}
          </PersonalInformationContainer>
        </DetailsContainer>
      </HeaderCard>
    </PageStateContainer>
  );
};

function getStatusInformation(status: UserStatus, isFullyRegistered: boolean) {
  let statusChip: ComponentProps<typeof Chip>['status'];
  let statusMessage = '';
  let chipMessageCode: string;
  let userStatus: ComponentProps<typeof Status>['status'];
  let icon: FC<IconProps> | undefined;

  switch (status) {
    case UserStatus.APPROVED:
      statusChip = 'success';
      chipMessageCode = 'account.fullyRegistered';
      userStatus = 'approved';
      break;
    case UserStatus.PENDING:
      if (isFullyRegistered) {
        statusChip = 'warning';
        chipMessageCode = 'account.pending';
        userStatus = 'pending';
        icon = AlertTriangleIcon;
        statusMessage = 'account.pendingInformation';
      } else {
        statusChip = 'error';
        chipMessageCode = 'account.notFullyRegistered';
        userStatus = 'error';
        icon = AlertIcon;
        statusMessage = 'account.missingInformation';
      }
      break;
    default:
      statusChip = 'warning';
      chipMessageCode = 'account.pending';
      userStatus = 'pending';
      icon = AlertTriangleIcon;
      statusMessage = 'account.pendingInformation';
  }

  return { statusChip, chipMessageCode, userStatus, icon, statusMessage };
}
