import { InputTooltip } from '@metaswiss/lib/src/components/input-tooltip/InputTooltip.tsx';
import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useOutsideClick } from '../..';
import { AlertIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from '../../../iconography';
import { DropdownHeight, DropdownWidth, IconSize, RemSize } from '../../../theme/theme';
import { Flex } from '../../../v2/index.ts';
import { ThemedIcon } from '../../molecules/theme-icon';
import { Checkbox } from '../checkbox';
import { StartIconWrapper, StyledAlertIconWrapper } from '../input/input.styles.ts';
import { Text } from '../text';

import { CheckContainer } from './styles/checkContainer';
import { CheckboxContainer } from './styles/checkboxContainer';
import { DropdownButton } from './styles/dropdownButton';
import { DropdownContainer } from './styles/dropdownContainer';
import { DropdownItem } from './styles/dropdownItem';
import { DropdownList, Image } from './styles/dropdownList';
import { DropdownText } from './styles/dropdownText';
import { EllipsisContainer } from './styles/ellipsisContainer';
import { Fieldset } from './styles/fieldset';
import { IconContainer } from './styles/iconContainer';
import { Label } from './styles/label';
import { Legend } from './styles/legend';
import { SelectionContainer } from './styles/selectionContainer';
import { Span } from './styles/span';
import { StyledError } from './styles/styledError';

export interface DropdownItem {
  id: string;
  label: string | JSX.Element;
  icon?: string;
  value?: string;
}

export type DropdownProps = {
  options: DropdownItem[];
  selectedItems: DropdownItem[];
  onSelect: (value: DropdownItem[]) => void;
  label: string;
  disableFloat?: boolean;
  fill?: boolean;
  width?: DropdownWidth;
  height?: DropdownHeight;
  displayType?: 'text' | 'textWithIcon';
  multiSelect?: boolean;
  error?: string;
  disabled?: boolean;
  isLocked?: boolean;
  alertIconShown?: boolean;
  scrollToView?: boolean;
  renederElement?: JSX.Element;
  renderStartIcon?: FC<{ iconSize: IconSize }>;
  tooltipDescrption?: string;
  showValue?: boolean;
  tootlipPosition?: {
    leftPostion?: RemSize;
    topPosition?: RemSize;
  };
};

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  label,
  width = 'large',
  height = 'large',
  fill = false,
  disableFloat = false,
  selectedItems,
  displayType = 'text',
  multiSelect = false,
  error,
  disabled = false,
  isLocked = false,
  alertIconShown = false,
  onSelect,
  scrollToView = false,
  renederElement,
  renderStartIcon,
  showValue = false,
  tootlipPosition,
  tooltipDescrption,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dropdownItemRef = useRef<(HTMLButtonElement | null)[]>([]);

  const display: string | JSX.Element = useMemo(() => {
    if (selectedItems.length > 0 && typeof selectedItems[0]?.label === 'string') {
      return selectedItems?.map((selectedItem) => selectedItem?.label).join(', ');
    }
    if (selectedItems.length > 0 && typeof selectedItems[0]?.label !== 'string') {
      return (
        <EllipsisContainer>
          {selectedItems?.map((selectedItem) => selectedItem?.label as JSX.Element)}
        </EllipsisContainer>
      );
    }
    return '';
  }, [selectedItems]);

  const handleOutsideClick = () => {
    setIsOpen(false);
  };

  const currentIndex = useMemo(() => {
    const selectedIds = selectedItems.map((item) => item.id);
    return options.findIndex((option) => selectedIds.includes(option.id));
  }, [selectedItems, options]);

  const handleOptionSelect = (selectedOption: DropdownItem) => {
    let newSelection = [...selectedItems];

    // single select variant
    if (!multiSelect) {
      newSelection[0] = selectedOption;
      setIsOpen(false);
      onSelect(newSelection);
      return;
    }
    // checkbox variant
    // remove already selected item
    if (selectedItems?.some((item) => item.id === selectedOption.id)) {
      newSelection = selectedItems.filter((item) => item.id !== selectedOption.id);
      // else append it
    } else {
      newSelection = [...selectedItems, selectedOption];
    }
    onSelect(newSelection);
  };

  useOutsideClick([dropdownRef], handleOutsideClick);

  const handleIconClick = () => {
    event?.stopPropagation();
    event?.preventDefault();
  };

  useEffect(() => {
    const handleScrollDown = (currentIndex: number) => {
      if (dropdownItemRef.current[currentIndex] && isOpen) {
        dropdownItemRef.current[currentIndex]?.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    };
    handleScrollDown(currentIndex);
  }, [isOpen, currentIndex]);

  const startIcon = renderStartIcon?.({ iconSize: 'medium' });

  return (
    <DropdownContainer ref={dropdownRef} $height={height} $width={width} $fill={fill} $isLocked={isLocked}>
      <>
        <DropdownButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          disabled={disabled}
          type="button"
          $isLocked={isLocked}
          $height={height}
          $isDisabled={disabled}
        >
          <Fieldset $isError={error !== undefined} $isLocked={isLocked}>
            <Legend $disableFloat={disableFloat} $showLabel={!!display} $isLocked={isLocked}>
              <Span>{label}</Span>
            </Legend>
          </Fieldset>
          {!display && alertIconShown && isLocked && (
            <StyledAlertIconWrapper>
              <ThemedIcon icon={AlertIcon} palette="error" strokeColor="hue400" size="medium" />
            </StyledAlertIconWrapper>
          )}
          {startIcon && !display && <StartIconWrapper>{startIcon}</StartIconWrapper>}
          <Label
            $isError={error !== undefined}
            $disableFloat={disableFloat}
            $transition={selectedItems?.length > 0}
            $isLocked={isLocked}
            $hasValue={!!display}
            $isIconShown={alertIconShown}
            $isDisabled={disabled}
            $isStartIconShown={!!renderStartIcon}
          >
            {label}
          </Label>
          <SelectionContainer $transition={!!display}>
            {!!display && displayType === 'textWithIcon' && selectedItems && selectedItems[0]?.icon && (
              <IconContainer>
                <ThemedIcon
                  icon={() => <Image src={selectedItems[0].icon} />}
                  size="full"
                  palette="neutrals"
                  strokeColor="hue400"
                />
              </IconContainer>
            )}
            {display && (
              <EllipsisContainer>
                <Flex flexDirection="row" gap="0.5rem">
                  {renderStartIcon && <StartIconWrapper>{startIcon}</StartIconWrapper>}
                  <DropdownText $isDisabled={disabled} $fontWeight={height === 'large' ? 'semi' : 'reg'}>
                    {display}
                  </DropdownText>
                </Flex>
              </EllipsisContainer>
            )}
          </SelectionContainer>
          {renederElement && <div style={{ zIndex: 10 }}>{renederElement}</div>}
          {!isLocked && (
            <div>
              <ThemedIcon
                icon={isOpen ? ChevronUpIcon : ChevronDownIcon}
                palette="neutrals"
                strokeColor="hue700"
                size="medium"
              />
            </div>
          )}
        </DropdownButton>

        <AnimatePresence mode={'sync'}>
          {isOpen && (
            <DropdownList
              $open={isOpen}
              $height={height}
              initial={{ top: '90%', opacity: 0 }}
              animate={{ top: '100%', opacity: 1 }}
              exit={{ top: '90%', opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {options.map((option, index) => {
                const selected = selectedItems.some((selectedItem) => selectedItem.id === option.id);
                return (
                  <DropdownItem
                    key={option.id}
                    $height={height}
                    selected={selected}
                    type="button"
                    ref={(el) => {
                      if (scrollToView) dropdownItemRef.current[index] = el;
                    }}
                    onClick={() => handleOptionSelect(option)}
                  >
                    {multiSelect && (
                      <CheckboxContainer onClick={handleIconClick}>
                        <Checkbox id={option.id} checked={selected} onChange={event?.preventDefault} />
                      </CheckboxContainer>
                    )}

                    {option?.icon && (
                      <IconContainer>
                        <ThemedIcon
                          icon={() => <Image src={option.icon} />}
                          size="full"
                          palette={selected ? 'primary' : 'neutrals'}
                          strokeColor={selected ? 'hue300' : 'hue400'}
                        />
                      </IconContainer>
                    )}
                    {typeof option.label === 'string' ? (
                      <EllipsisContainer>
                        <DropdownText $isDisabled={disabled} $fontWeight={selected ? 'bold' : 'reg'}>
                          {option?.label}
                        </DropdownText>
                      </EllipsisContainer>
                    ) : (
                      <EllipsisContainer $isDisabled={disabled}>{option.label as JSX.Element}</EllipsisContainer>
                    )}
                    {showValue && <Text fontWeight="bold">{option.value}</Text>}
                    {!multiSelect && (
                      <CheckContainer $show={selected && !multiSelect}>
                        <ThemedIcon icon={CheckIcon} size="medium" palette="primary" strokeColor="hue300" />
                      </CheckContainer>
                    )}
                  </DropdownItem>
                );
              })}
            </DropdownList>
          )}
        </AnimatePresence>
      </>
      {(tooltipDescrption && startIcon) || !!display ? (
        <InputTooltip
          description={tooltipDescrption || ''}
          leftHoverPosition={'1.35rem'}
          topHoverPosition={'1rem'}
          leftPosition={tootlipPosition?.leftPostion ?? '0.7rem'}
          topPosition={tootlipPosition?.topPosition ?? '-0.8rem'}
        />
      ) : null}
      {error && <StyledError>{error}</StyledError>}
    </DropdownContainer>
  );
};
