import {
  Heading,
  PortfolioBondIcon,
  PortfolioCollectibleIcon,
  PortfolioShareIcon,
  Text,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { ProductType } from '../../../../../../enums/productType.enum';
import { useTenantConfig } from '../../../../../../hooks/use-tenant-config/useTenantConfig';

import { BannerContainer, IconWrapper, ImageWrapper, TextsWrapper } from './portfolioBanner.styles';

type PortfolioBannerProps = {
  type: ProductType;
  heading: string;
  text: string;
};

export const PortfolioBanner = ({ type, heading, text }: PortfolioBannerProps) => {
  const theme = useTheme();
  const tenantConfig = useTenantConfig();
  const bannerGradientColor = useMemo(() => {
    switch (type) {
      case ProductType.NFT:
        return `linear-gradient(307.69deg, ${theme.v2.colors.tertiary200} -32.45%, ${theme.v2.colors.tertiary50} 111.94%);`;
      case ProductType.ACTIONS:
        return `linear-gradient(307.69deg, ${theme.v2.colors.primary200} -32.45%, ${theme.v2.colors.primary50} 111.94%);`;
      case ProductType.BOND_PAPERS:
        return `linear-gradient(307.69deg, ${theme.v2.colors.secondary200} -32.45%, ${theme.v2.colors.secondary50} 111.94%);`;
      default:
        throw new Error('Wrong product type selected!');
    }
  }, [type, theme.v2.colors]);
  const bannerPictureUrl = useMemo(
    () =>
      getBannerUrl(
        type,
        tenantConfig.portfolioNftBannerIcon,
        tenantConfig.portfolioSharesBannerIcon,
        tenantConfig.portfolioBondsBannerIcon
      ),
    [
      tenantConfig.portfolioBondsBannerIcon,
      tenantConfig.portfolioNftBannerIcon,
      tenantConfig.portfolioSharesBannerIcon,
      type,
    ]
  );

  const icon = useMemo(() => getIcon(type), [type]);

  //TODO - this component is just for the presentation purposes. We will get these information from the be later

  return (
    <BannerContainer $gradientColor={bannerGradientColor}>
      <TextsWrapper>
        <Heading headingType={'h4'} fontWeight={'bold'} color={theme.v2.text.onAction}>
          {heading}
        </Heading>
        <Text textColor={'hue100'} color={theme.v2.text.onAction}>
          {text}
        </Text>
      </TextsWrapper>
      <IconWrapper>
        <ThemedIcon icon={icon} size={'full'} customStrokeColor={theme.v2.text.onAction} />
      </IconWrapper>
      <ImageWrapper src={bannerPictureUrl} />
    </BannerContainer>
  );
};

const getBannerUrl = (type: ProductType, nftsBannerIcon: string, sharesBannerIcon: string, bondsBannerIcon: string) => {
  switch (type) {
    case ProductType.NFT:
      return nftsBannerIcon;
    case ProductType.ACTIONS:
      return sharesBannerIcon;
    case ProductType.BOND_PAPERS:
      return bondsBannerIcon;
    default:
      throw new Error('Wrong product type selected!');
  }
};

const getIcon = (type: ProductType) => {
  switch (type) {
    case ProductType.ACTIONS:
      return PortfolioShareIcon;
    case ProductType.BOND_PAPERS:
      return PortfolioBondIcon;
    case ProductType.NFT:
      return PortfolioCollectibleIcon;
    default:
      throw new Error('Wrong product type selected!');
  }
};
