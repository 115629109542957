import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { styled } from 'styled-components';

export const BuyCardsContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1.25rem;

  @media ${breakpoints.laptopMd} {
    margin-bottom: 0.25rem;
  }

  @media ${breakpoints.desktopSm} {
    margin-bottom: 0.5rem;
  }
`;

export const NoProductsWrapper = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.large};
`;

export const NoProuctsHeadingWrapper = styled.div`
  margin: 1rem 0 0.5rem 0;
`;

export const NoProductsIconWrapper = styled.div`
  width: fit-content;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.v2.surface.informationLight};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.medium};
`;
