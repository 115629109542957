import { CheckIcon, CloseSmallIcon, IconProps } from '@metaswiss/ui-kit';

import { RegistrationLength } from '../../../../../enums/registrationLength';

export type QucikRegistrationData = {
  title: RegistrationLength;
  info: QuickRegistrationInfo[];
};

export type QuickRegistrationInfo = {
  option: string;
  label: string;
  hasClose?: boolean;
  icon: React.FC<IconProps>;
};

export const registrationSecondStepPrivate: QucikRegistrationData[] = [
  {
    title: RegistrationLength.FULL,
    info: [
      { option: 'registration.view', label: 'registration.shares', icon: CheckIcon },
      { option: 'registration.view', label: 'registration.bonds', icon: CheckIcon },
      { option: 'registration.view', label: 'registration.collectibles', icon: CheckIcon },
      { option: 'registration.buy', label: 'registration.shares', icon: CheckIcon },
      { option: 'registration.buy', label: 'registration.bonds', icon: CheckIcon },
      { option: 'registration.buy', label: 'registration.collectibles', icon: CheckIcon },
    ],
  },
  {
    title: RegistrationLength.QUICK,
    info: [
      { option: 'registration.view', label: 'registration.shares', icon: CheckIcon },
      { option: 'registration.view', label: 'registration.bonds', icon: CheckIcon },
      { option: 'registration.view', label: 'registration.collectibles', icon: CheckIcon },
      { option: 'registration.buy', label: 'registration.shares', hasClose: true, icon: CloseSmallIcon },
      { option: 'registration.buy', label: 'registration.bonds', hasClose: true, icon: CloseSmallIcon },
      { option: 'registration.buy', label: 'registration.collectibles', icon: CheckIcon },
    ],
  },
];

export const registrationSecondStepCorporate: QucikRegistrationData[] = [
  {
    title: RegistrationLength.FULL,
    info: [
      { option: 'registration.view', label: 'registration.shares', icon: CheckIcon },
      { option: 'registration.view', label: 'registration.bonds', icon: CheckIcon },
      { option: 'registration.view', label: 'registration.collectibles', icon: CheckIcon },
      { option: 'registration.buy', label: 'registration.shares', icon: CheckIcon },
      { option: 'registration.buy', label: 'registration.bonds', icon: CheckIcon },
      { option: 'registration.buy', label: 'registration.collectibles', icon: CheckIcon },
    ],
  },
  {
    title: RegistrationLength.QUICK,
    info: [
      { option: 'registration.view', label: 'registration.shares', icon: CheckIcon },
      { option: 'registration.view', label: 'registration.bonds', icon: CheckIcon },
      { option: 'registration.view', label: 'registration.collectibles', icon: CheckIcon },
      { option: 'registration.buy', label: 'registration.shares', hasClose: true, icon: CloseSmallIcon },
      { option: 'registration.buy', label: 'registration.bonds', hasClose: true, icon: CloseSmallIcon },
      { option: 'registration.buy', label: 'registration.collectibles', icon: CheckIcon },
    ],
  },
];
