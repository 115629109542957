import { useTenantConfig } from '../../../../../hooks/use-tenant-config/useTenantConfig';
import { AllShares } from '../all-shares/AllShares';
import { SingleShare } from '../single-share/SingleShare';

export const SharesHandler = () => {
  const tenantConfig = useTenantConfig();

  if (!tenantConfig.singleShare) {
    return <AllShares />;
  }

  return <SingleShare />;
};
