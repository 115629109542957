import { motion } from 'framer-motion';
import styled from 'styled-components';

import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';
import { RemSize } from '../../../../theme';

export const Container = styled(motion.div)<{ $top: RemSize; $transformOrigin: string }>`
  position: fixed;
  top: ${({ $top }) => $top};
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.neutrals.hue0};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 17.5rem;
  transform-origin: ${({ $transformOrigin }) => $transformOrigin};
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.08);
  z-index: 1;
`;

export const ItemWrapper = styled.div<{
  $isSelected: boolean;
}>`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.buttons.large};
  background-color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.v2.surface.informationLight : theme.v2.surface.primary};

  &:hover {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.v2.surface.informationLight : theme.v2.surface.secondary};
  }

  &:active {
    background-color: ${({ theme }) => theme.v2.surface.informationLight};
  }
`;

export const Label = styled(TextTemplate)<{ $isSelected: boolean }>`
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.disabled)};

  div:hover > & {
    color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.headingPrimary)};
  }

  div:active > & {
    color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.headingPrimary)};
  }

  white-space: nowrap;
`;

export const MainIcon = styled.span<{ $isSelected: boolean }>`
  path {
    stroke-width: 1.5;
    stroke: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.icon.primary : theme.v2.icon.neutral)};

    ${ItemWrapper}:hover & {
      stroke: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.icon.primary : theme.v2.icon.default)};
    }

    ${ItemWrapper}:active & {
      stroke: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.icon.primary : theme.v2.icon.default)};
    }
  }
`;
