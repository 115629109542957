import { FC, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { usePermissions } from '../../../hooks/use-permissions/usePermissions';
import { useSetRouteNavigation } from '../../../hooks/use-set-route-navigation/useSetRouteNavigation';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes';

import { portfolioTabs } from './values/portfolioTabs.values';

export const Portfolio: FC = () => {
  const { textTranslation } = useTextTranslation();
  const { pathname } = useLocation();
  const permissions = usePermissions();

  const portfolioTabsWithTranslation = useMemo(() => {
    return portfolioTabs.reduce(
      (acc, tab) => {
        if (tab.permission && !permissions[tab.permission]) {
          return acc;
        }

        acc.push({
          ...tab,
          label: textTranslation(`portfolio.${tab.value}`),
        });

        return acc;
      },
      [] as typeof portfolioTabs
    );
  }, [permissions]);

  const isPortfolioDeepNestedRoute = useMemo(() => pathname.split('/').length > 3, [pathname]);

  useSetRouteNavigation(
    portfolioTabsWithTranslation,
    !isPortfolioDeepNestedRoute ? routes.portfolio.overview : undefined
  );

  return <Outlet />;
};
