import { AssetResponse, CorporateUserFullResponse } from '@metaswiss/api';
import { mapIsoStringToDate } from '@metaswiss/lib';
import { ItemProps } from '@metaswiss/ui-kit';
import { DropdownItem } from '@metaswiss/ui-kit/src/components/atoms/dropdown/Dropdown';

import { AssignedUserAssetEnum } from '../../../../../enums/assignedUserAssetEnum';
import { findAssetByType } from '../../../../../shared/helpers/findAssetByType.helper';
import { CorporateFormData } from '../schemas/corporateProfile.schema';

export const parseFormObjectCorporateUser = (
  response: CorporateUserFullResponse,
  businessFocus?: ItemProps,
  legalForm?: DropdownItem,
  phoneNumberPrefixCountry?: ItemProps,
  assets?: AssetResponse[]
): CorporateFormData => {
  const picture = assets ? findAssetByType(assets, AssignedUserAssetEnum.PICTURE) : undefined;
  return {
    ceo: response?.corporateUser?.ceo,
    owner: response?.corporateUser?.owner,
    phoneNumber: response?.phoneNumber,
    businessFocus: {
      label: businessFocus?.label ?? '',
      value: businessFocus?.value ?? '',
      iconSrc: businessFocus?.iconSrc ?? '',
      meta: businessFocus?.meta,
    },
    legalForm: [
      {
        id: legalForm?.id ?? '',
        label: (legalForm?.label as string) ?? '',
      },
    ],
    phoneNumberPrefix: {
      value: phoneNumberPrefixCountry?.value ?? '',
      label: phoneNumberPrefixCountry?.label ?? '',
      meta: phoneNumberPrefixCountry?.meta,
      iconSrc: phoneNumberPrefixCountry?.iconSrc,
    },
    dateFounded: response?.corporateUser?.dateFounded
      ? mapIsoStringToDate({ date: response.corporateUser.dateFounded })
      : '',
    picture: { fileId: picture?.id ?? '', url: picture?.key ?? '' },
  };
};
