import {
  BaseCard,
  Button,
  FaceIdIcon,
  FileIcon,
  Indicator,
  Line2,
  RadioButton,
  Stepper,
  Text,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { IdentityVerification } from '../../../../../../enums/identityVerification';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { DocumentOption, useVerificationDocumentContext } from '../../shared/context/VerificationDocumentContext';
import {
  ButtonWrapper,
  ContentWrapper,
  Description,
  DocumentTypeContainer,
  Graphic,
  LineContainer,
  ModalMain,
  OptionsContainer,
  RadioButtonWrapper,
  SecondStepContainer,
  StepContainer,
  StepSection,
  StepWrapper,
  SubHeaderSection,
} from '../../styles/verification.styles';

type Props = {
  openModal: () => void;
  closeModal: () => void;
};

export const FirstStep: FC<Props> = ({ openModal, closeModal }) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  const { identityProofFlow, setIdentityProofFlow } = useVerificationDocumentContext();

  const [currentDocument, setCurrentDocument] = useState<IdentityVerification | undefined>(undefined);

  const isDirtyForm = useMemo(
    () =>
      identityProofFlow.document?.id === IdentityVerification.ID
        ? !!identityProofFlow.backSide?.fileId || !!identityProofFlow.frontSide?.fileId
        : !!identityProofFlow.frontSide?.fileId,
    [identityProofFlow.document?.id, identityProofFlow.frontSide, identityProofFlow.backSide]
  );

  const DocTypeOptions: DocumentOption[] = [
    { id: IdentityVerification.ID, value: textTranslation('account.id') },
    { id: IdentityVerification.PASSPORT, value: textTranslation('account.passport') },
  ];

  useEffect(() => {
    if (!currentDocument) {
      setCurrentDocument(identityProofFlow?.document?.id);
    } else {
      if (currentDocument !== identityProofFlow.document?.id) {
        setIdentityProofFlow({ ...identityProofFlow, frontSide: undefined, backSide: undefined });
      }
    }
  }, [identityProofFlow.document?.id]);

  return (
    <>
      <StepSection>
        <StepWrapper>
          <StepContainer>
            <Indicator number={1} active={true} />
            <Text fontWeight={'semi'} fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.headingPrimary}>
              {textTranslation('account.documentType')}
            </Text>
          </StepContainer>
          <LineContainer>
            <ThemedIcon icon={Line2} customStrokeColor={theme.v2.border.primary} size={'full'} />
          </LineContainer>
          <SecondStepContainer>
            <Indicator number={2} active={false} />
            <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.headingPrimary}>
              {textTranslation('account.uploadingDocuments')}
            </Text>
          </SecondStepContainer>
        </StepWrapper>
      </StepSection>
      <ModalMain>
        <ContentWrapper>
          <SubHeaderSection>
            <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
              {textTranslation('account.chooseDocumentType')}
            </Text>
            <Text color={theme.v2.text.headingPrimary}>{textTranslation('account.chooseBetween')}</Text>
          </SubHeaderSection>
          <OptionsContainer $step={0}>
            <DocumentTypeContainer>
              <BaseCard
                isSelected={identityProofFlow.document?.id === IdentityVerification.ID}
                onClick={() => setIdentityProofFlow({ ...identityProofFlow, document: DocTypeOptions[0] })}
                padding={'0rem'}
                height={'100%'}
                override={{
                  backgroundColor: theme.v2.surface.primary,
                  hoverBackgroundColor: theme.v2.surface.field,
                  activeBackgroundColor: theme.v2.surface.field,
                  borderColor: theme.v2.border.primary,
                  hoverBorderColor: theme.v2.border.primary,
                  activeBorderColor: theme.v2.border.action,
                  borderRadius: theme.v2.radius.medium,
                }}
              >
                <RadioButtonWrapper>
                  <RadioButton
                    onSelect={() => setIdentityProofFlow({ ...identityProofFlow, document: DocTypeOptions[0] })}
                    selected={identityProofFlow.document}
                    label={DocTypeOptions[0]}
                    size={'large'}
                  />
                  <Description>
                    <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.headingPrimary}>
                      {textTranslation('account.frontAndBackSide')}
                    </Text>
                  </Description>
                </RadioButtonWrapper>
                <Graphic>
                  <ThemedIcon icon={FaceIdIcon} customStrokeColor={theme.v2.surface.tertiary} size={'full'} />
                </Graphic>
              </BaseCard>
              <BaseCard
                isSelected={identityProofFlow.document?.id === IdentityVerification.PASSPORT}
                onClick={() => setIdentityProofFlow({ ...identityProofFlow, document: DocTypeOptions[1] })}
                padding={'0rem'}
                height={'100%'}
                override={{
                  backgroundColor: theme.v2.surface.primary,
                  hoverBackgroundColor: theme.v2.surface.field,
                  activeBackgroundColor: theme.v2.surface.field,
                  borderColor: theme.v2.border.primary,
                  hoverBorderColor: theme.v2.border.primary,
                  activeBorderColor: theme.v2.border.action,
                  borderRadius: theme.v2.radius.medium,
                }}
              >
                <RadioButtonWrapper>
                  <RadioButton
                    onSelect={() => setIdentityProofFlow({ ...identityProofFlow, document: DocTypeOptions[1] })}
                    selected={identityProofFlow.document}
                    label={DocTypeOptions[1]}
                    size={'large'}
                  />
                  <Description>
                    <Text fontSize={'sm'} lineHeight={'medium'}>
                      {textTranslation('account.frontSide')}
                    </Text>
                  </Description>
                </RadioButtonWrapper>
                <Graphic>
                  <ThemedIcon icon={FileIcon} customStrokeColor={theme.v2.surface.tertiary} size={'full'} />
                </Graphic>
              </BaseCard>
            </DocumentTypeContainer>
          </OptionsContainer>
          <Stepper currentStep={0} numberOfSteps={2} />
        </ContentWrapper>
        <ButtonWrapper>
          <Button
            variant={'outlined'}
            color={'neutral'}
            text={textTranslation('account.cancel')}
            onClick={isDirtyForm ? openModal : closeModal}
            fill
          />
          <Button
            text={textTranslation('account.continue')}
            fill
            disabled={!identityProofFlow.document}
            onClick={() =>
              setIdentityProofFlow({
                ...identityProofFlow,
                step: 1,
              })
            }
          />
        </ButtonWrapper>
      </ModalMain>
    </>
  );
};
