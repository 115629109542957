import { motion } from 'framer-motion';
import styled from 'styled-components';

import { breakpoints } from '../../../../breakpoints';
import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';

export const Container = styled.div<{
  $isSelected: boolean;
  $isActive: boolean;
}>`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.xl};
  background-color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.v2.surface.informationLight : theme.v2.surface.primary};

  &:hover {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.v2.surface.informationLight : theme.v2.surface.secondary};
  }

  &:active {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.v2.surface.informationLight : theme.v2.surface.primary};
  }
`;

export const Label = styled(TextTemplate)<{ $isSelected: boolean; $isSubItem?: boolean; $isActive: boolean }>`
  display: ${({ $isSubItem }) => ($isSubItem ? 'block' : 'none')};
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.disabled)};

  div:hover > & {
    color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.bodySecondary)};
  }

  div:active > & {
    color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.bodySecondary)};
  }

  ${({ theme, $isActive, $isSelected }) =>
    $isActive &&
    `color: ${
      $isSelected ? theme.v2.text.action : theme.v2.text.bodySecondary
    } !important;`} @media ${breakpoints.desktopSm} {
    display: block;
  }
  white-space: nowrap;
`;

export const IconsWrapper = styled.span<{
  $isSelected: boolean;
  $isExpandable?: boolean;
  $isActive: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease-in-out;

  ${Container}:hover & {
    position: relative;
    transform: ${({ $isExpandable, $isActive }) => ($isExpandable && !$isActive ? 'translateX(-0.25rem)' : '0')};
  }

  @media ${breakpoints.desktopSm} {
    ${Container}:hover & {
      position: static;
      transform: none;
    }
  }
`;

export const MainIcon = styled.span<{ $isSelected: boolean; $isExpandable?: boolean; $isActive: boolean }>`
  path {
    stroke-width: 1.5;
    stroke: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.disabled)};

    ${Container}:hover & {
      stroke: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.icon.default)};
    }

    ${Container}:active & {
      stroke: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.icon.default)};
    }

    ${({ theme, $isActive, $isSelected }) =>
      $isActive && `stroke: ${$isSelected ? theme.v2.text.action : theme.v2.icon.default} !important;`}
  }
`;

export const ExpandableIcon = styled(MainIcon)<{ $isSelected: boolean; $isActive: boolean }>`
  width: 1rem;
  height: 1rem;
  display: none;

  ${Container}:hover & {
    display: ${({ $isActive }) => ($isActive ? 'none' : 'block')};
  }

  @media ${breakpoints.desktopSm} {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: 1rem;
    display: block;
    ${Container}:hover & {
      display: block;
    }

    transition: 0.1s ease-in-out;
    ${({ $isActive }) => ($isActive ? 'transform: rotate(-180deg)' : '')};
  }
`;

export const Tooltip = styled(motion.div)`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  right: 0;
  transform: translateX(calc(100% + 1rem));
  background-color: ${({ theme }) => theme.v2.surface.tertiary};
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
  white-space: nowrap;

  ::before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 50%;
    left: 0;
    transform: translate(-30%, -50%) rotate(-135deg);
    z-index: -1;
    background-color: ${({ theme }) => theme.v2.surface.tertiary};
    border-radius: ${({ theme }) => theme.dimensions.borderRadii.small};
  }

  ${Container}:hover & {
    display: flex;
  }

  @media ${breakpoints.desktopSm} {
    ${Container}:hover & {
      display: none;
    }
  }
`;

export const SubItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 1rem 0.5rem 3.5rem;
  transition: 0.1s ease-in-out;

  &:hover {
    gap: 1rem;
  }
`;

export const Dot = styled.div<{ $isSelected: boolean }>`
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background-color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.disabled)};
  flex-shrink: 0;

  ${SubItemContainer}:hover & {
    background-color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.bodySecondary)};
  }
`;
