export enum ResourceInfoVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  NEUTRALS = 'neutrals',
  DISABLED = 'disabled',
}

export enum ResourceInfoSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  MEDIUM_LARGE = 'medium-large',
  LARGE = 'large',
}
