import { Variants } from 'framer-motion';
import React, { FC, forwardRef } from 'react';
import { useTheme } from 'styled-components';

import { ThemedIcon } from '../../../../components';
import { IconProps } from '../../../../iconography/iconProps.ts';
import { RemSize } from '../../../../theme';

import { Container, ItemWrapper, Label, MainIcon } from './subMenu.styles.ts';

type Item = {
  isSelected?: boolean;
  label: string;
  value: string;
  route: string;
  icon: FC<IconProps>;
};

type SubMenuProps = {
  items: Item[];
  top: RemSize;
  left: RemSize;
  onClick: (item: Item) => void;
  selectedTab: string | undefined;
  transformOrigin?: string;
};

const subMenuAnimationVariants: Variants = {
  initial: { opacity: 0, y: -10, scale: 0 },
  animate: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, y: -10, scale: 0 },
};

export const SubMenu = forwardRef((props: SubMenuProps, ref: React.Ref<HTMLDivElement>) => {
  const theme = useTheme();
  const { items, top, transformOrigin = 'top left', onClick, selectedTab } = props;

  return (
    <Container
      $top={top}
      ref={ref}
      $transformOrigin={transformOrigin}
      variants={subMenuAnimationVariants}
      initial={'initial'}
      animate={'animate'}
      exit={'exit'}
      transition={{ duration: 0.1, ease: 'easeInOut' }}
    >
      {items.map((item) => {
        const { label, icon } = item;
        const isSelected = item.value === selectedTab;
        return (
          <ItemWrapper $isSelected={isSelected} onClick={() => onClick(item)} key={`subItem${label}`}>
            <MainIcon $isSelected={isSelected}>
              <ThemedIcon icon={icon} size={'medium'} customStrokeColor={theme.v2.icon.neutral} />
            </MainIcon>
            <Label
              $fontSize="base"
              $fontWeight={isSelected ? 'semi' : 'reg'}
              $lineHeight="base"
              as="span"
              $isSelected={isSelected}
            >
              {label}
            </Label>
          </ItemWrapper>
        );
      })}
    </Container>
  );
});
