import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { BackgroundCircle, Container, ProgressCircle, StyledText, Wrapper } from './circularStepProgress.styles';

export type CircularStepProgressProps = {
  totalSteps: number;
  currentStep: number;
  animationDuration?: number;
};

const radius = 26;
const calculateCircumference = (): number => 2 * Math.PI * radius;

const CircularStepProgress: React.FC<CircularStepProgressProps> = ({
  totalSteps,
  currentStep,
  animationDuration = 1,
}) => {
  const theme = useTheme();
  const circumference: number = useMemo(() => calculateCircumference(), []);
  const isBadProps = useMemo(
    () => totalSteps < 0 || currentStep < 0 || currentStep > totalSteps,
    [totalSteps, currentStep]
  );
  const progress: number = useMemo(() => {
    let stepProgress = 0;
    if (!isBadProps) {
      stepProgress = currentStep / totalSteps;
    }
    return circumference * (1 - stepProgress);
  }, [isBadProps, currentStep, totalSteps, circumference]);

  return (
    <Wrapper>
      <Container viewBox="0 0 60 60">
        <BackgroundCircle r={radius} cx={30} cy={30} />
        <ProgressCircle
          r={radius}
          cx={30}
          cy={30}
          initial={false}
          animate={{ strokeDashoffset: progress }}
          transition={{
            duration: animationDuration,
            ease: 'linear',
          }}
          circumference={circumference}
          offset={progress}
        />
      </Container>
      <StyledText $color={theme.v2.icon.primary} $fontSize={'sm'} $fontWeight={'semi'} $lineHeight="medium">{`${
        isBadProps ? 0 : currentStep
      }/${isBadProps ? 0 : totalSteps}`}</StyledText>
    </Wrapper>
  );
};

export { CircularStepProgress };
