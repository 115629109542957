import { Filter, SelectSection } from '@metaswiss/ui-kit';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { FilterType } from '../../enums/filterType.enum.ts';
import { useHeaderOptions } from '../../global-state/zustand.ts';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation.ts';
import { FilterItem } from '../../pages/protected/account/transactions/all-transactions/AllTransactions.tsx';

import { SelectSectionsContainer } from './filters.styles.ts';

type FilterProps = {
  id: string;
  label: string;
  value: string;
};

type Props<T> = {
  filterOptions: {
    id: string;
    title: string;
    param: string;
    options: FilterProps[];
  }[];
  setQuery: Dispatch<SetStateAction<T>>;
  totalItems: number;
  isFilterButtonDisplayed?: boolean;
};

export const Filters = <T,>({ filterOptions, setQuery, totalItems, isFilterButtonDisplayed = true }: Props<T>) => {
  const { setIsFilterDisplayed, isFilterMenuOpen, selectedFilters, setSelectedFilters, setisFilterButtonDisplayed } =
    useHeaderOptions((state) => state);

  const [isResultsUpdated, setIsResultsUpdated] = useState<boolean>(false);
  const { textTranslation } = useTextTranslation();

  const onSelect = (id: string, label: string, value: string, param: string) => {
    toggleSelectedItems(id, label, value, param);
    setIsResultsUpdated(false);
  };

  const toggleSelectedItems = (id: string, label: string, value: string, param: string) => {
    if (selectedFilters.some((selectedItem) => selectedItem.id === id)) {
      setSelectedFilters(selectedFilters.filter((selectedFilters: FilterItem) => selectedFilters.id !== id));
    } else {
      setSelectedFilters([...selectedFilters, { id, label, value, param }]);
    }
  };

  const selectAllHandler = (items: { id: string; label: string; value: string }[], param: string) => {
    const doFiltersContainAllItems = items.every((item) =>
      selectedFilters.some((selectedFilter) => selectedFilter.id === item.id)
    );

    if (doFiltersContainAllItems) return;

    const uniqueItemsToAdd = items.filter(
      (item) => !selectedFilters.some((selectedFilter: FilterItem) => selectedFilter.id === item.id)
    );

    setSelectedFilters([...selectedFilters, ...uniqueItemsToAdd.map((item) => ({ ...item, param }))]);
  };

  useEffect(() => {
    const query: { [param: string]: string[] } = selectedFilters.reduce(
      (acc: { [param: string]: string[] }, item: FilterItem) => {
        if (item.param) {
          acc[item.param] = acc[item.param] || [];
          acc[item.param].push(item.value);
        }
        return acc;
      },
      {}
    );
    setQuery(query as T);
  }, [selectedFilters, setQuery]);

  useEffect(() => {
    setisFilterButtonDisplayed(isFilterButtonDisplayed);
  }, [isFilterButtonDisplayed, setisFilterButtonDisplayed]);

  useEffect(() => {
    return () => {
      setSelectedFilters([]);
      setIsFilterDisplayed(false);
    };
  }, []);

  const submitButtonText = useMemo(() => `${textTranslation('global.showResult')} (${totalItems})`, [totalItems]);

  const filteredSelectedFilters = useMemo(
    () => selectedFilters.filter((item) => item.value !== FilterType.SEARCH),
    [totalItems, isResultsUpdated]
  );

  return (
    <>
      <Filter
        isOpen={isFilterMenuOpen}
        setIsOpen={() => setIsFilterDisplayed(!isFilterMenuOpen)}
        title={textTranslation('global.filter')}
        clearTitle={textTranslation('global.clearAll')}
        isDisabledHeaderButton={filteredSelectedFilters.length === 0 || !totalItems}
        titleButtonOnClick={() => {
          setIsResultsUpdated(false);
          setSelectedFilters([]);
        }}
        submitButtonText={submitButtonText}
        children={
          <SelectSectionsContainer>
            {filterOptions.map((filterOption) => (
              <SelectSection
                key={filterOption.id}
                title={filterOption.title}
                options={filterOption.options}
                selectedItems={selectedFilters}
                onSelect={(id, label, value) => onSelect(id, label, value, filterOption.param)}
                cleanupButtonText={textTranslation('global.selectAll')}
                cleanupButtonOnClick={() => {
                  if (filteredSelectedFilters.length === 0) {
                    setIsResultsUpdated(true);
                  }
                  selectAllHandler(filterOption.options, filterOption.param);
                }}
              />
            ))}
          </SelectSectionsContainer>
        }
      />
    </>
  );
};
