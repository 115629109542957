import { NftCollectionResponse } from '@metaswiss/api';
import { DataStatusPage, ErrorRegionIcon, FlatList, PageStateContainer, ThemedIcon } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useMemo, useState } from 'react';

import { api } from '../../../../../api/msApi.ts';
import { NumberOfItems } from '../../../../../components/number-of-items/NumberOfItems.tsx';
import { ApiResource } from '../../../../../enums/resource.enum.ts';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation.ts';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper.ts';
import { CollectionCard } from '../components/collection-card/CollectionCard.tsx';
import { Response } from '../single-collection/SingleCollection.tsx';

export const Collections: FC = () => {
  const { textTranslation } = useTextTranslation();
  const [regionError, setRegionError] = useState<boolean>(false);
  const {
    data: nftCollections,
    isLoading: isLoadingNftCollection,
    isError: isErrorNftCollection,
    refetch: refetchNftCollection,
    error,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION),
    queryFn: () => api.nftCollection.getAllNftCollections(),
    retry: false,
  });

  useEffect(() => {
    const response = error as Response;
    if (response?.body?.code === 405) {
      setRegionError(true);
    }
  }, [error]);

  const isLoading = useMemo(() => {
    if (regionError) {
      return false;
    }
    return isLoadingNftCollection;
  }, [isLoadingNftCollection, regionError]);

  const isError = useMemo(() => {
    if (regionError) {
      return false;
    }

    return isErrorNftCollection;
  }, [isErrorNftCollection, regionError]);

  const onTryAgain = async () => {
    await Promise.all([refetchNftCollection()]);
  };

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      isError={isError}
      showError={true}
      showEmptyState={true}
      isEmptyState={nftCollections?.items.length === 0 || !nftCollections}
      onTryAgain={onTryAgain}
      emptyStateTitle={'offering.nothingHereYet'}
      emptyStateContent={'offering.noCollectiblesAvailable'}
      customEmptyStateComponent={
        regionError && (
          <DataStatusPage
            icon={<ThemedIcon icon={ErrorRegionIcon} size={'full'} />}
            title={textTranslation('global.nothingHere')}
            content={textTranslation('global.notPossibleCollectibles')}
            action={onTryAgain}
            iconWidth={'12rem'}
            iconHeight={'12rem'}
            buttonVariant={'outlined'}
            buttonColor={'neutral'}
          />
        )
      }
      textTranslation={textTranslation}
    >
      <FlatList<NftCollectionResponse>
        data={nftCollections?.items as unknown as NftCollectionResponse[]}
        headerComponent={<NumberOfItems numberOfAssets={nftCollections?.totalItems ?? 0} />}
        renderItem={(item) => <CollectionCard collection={item} />}
        keyExtractor={(item) => item.id}
        numColumns={2}
      />
    </PageStateContainer>
  );
};
