import { PuffLoader } from 'react-spinners';
import { LengthType } from 'react-spinners/helpers/props';

import { ResetGlobalStyles } from '../../../shared/styles/globalReset.styles';

import { LoaderContainer } from './initialLoader.styles';

type InitialLoaderProps = {
  size?: LengthType;
  color?: string;
};

export const InitialLoader = ({ size = 64, color = '#A6ABB2' }: InitialLoaderProps) => {
  return (
    <>
      <ResetGlobalStyles />
      <LoaderContainer>
        <PuffLoader size={size} color={color} />
      </LoaderContainer>
    </>
  );
};
