import { FC, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { usePermissions } from '../../../hooks/use-permissions/usePermissions';
import { useSetRouteNavigation } from '../../../hooks/use-set-route-navigation/useSetRouteNavigation';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes';

import { offeringTabs } from './shared/values/offeringTabs';

type OfferingRoutes = keyof typeof offeringRoutes;

const offeringRoutes = {
  nft: routes.offering.smartCollectibles.root,
  share: routes.offering.smartShares.root,
  bond: routes.offering.smartBonds.root,
};

export const Offering: FC = () => {
  const { textTranslation } = useTextTranslation();
  const { pathname } = useLocation();
  const permissions = usePermissions();

  const offeringTabsWithTranslation = useMemo(() => {
    return offeringTabs.reduce(
      (acc, tab) => {
        if (!tab.permission || !permissions[tab.permission]) {
          return acc;
        }

        acc.push({
          ...tab,
          label: textTranslation(`offering.${tab.value}`),
        });

        return acc;
      },
      [] as typeof offeringTabs
    );
  }, [permissions]);

  const pathnameIncludes = useMemo(
    () =>
      pathname.includes(offeringRoutes.nft) ||
      pathname.includes(offeringRoutes.share) ||
      pathname.includes(offeringRoutes.bond),
    [pathname]
  );

  const defaultRoute = useMemo(() => {
    const firstAllowedPermission = Object.keys(permissions).find((permission) => permissions[permission]);

    return offeringRoutes[firstAllowedPermission as OfferingRoutes];
  }, [permissions]);

  useSetRouteNavigation(offeringTabsWithTranslation, !pathnameIncludes ? defaultRoute : undefined);

  return <Outlet />;
};
