import { formatAmountWithoutDecimals } from '@metaswiss/lib';
import { DoughnutChart, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { OverviewGraphCard } from './OverviewGraphCard';
import {
  DescriptionWrapper,
  OverviewGraphCards,
  OverviewGraphChart,
  OverviewGraphContainer,
  OverviewGraphContent,
  StyledPortfolioCard,
} from './overviewGraph.styles';
import { OverviewGraphProps } from './overviewGraphTypes';

export const OverviewGraph: FC<OverviewGraphProps> = ({ title, price, titleCurrency, cards, chartData }) => {
  const theme = useTheme();

  return (
    <StyledPortfolioCard
      disableStateEffects
      height="auto"
      override={{
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
        borderRadius: theme.v2.componentsRadius.cards.default,
      }}
    >
      <OverviewGraphContainer>
        <OverviewGraphChart>
          <DoughnutChart segments={chartData} />
        </OverviewGraphChart>
        <OverviewGraphContent>
          <Text fontSize="base" fontWeight="semi" lineHeight="base" color={theme.v2.text.disabled}>
            {title.toString()}
          </Text>
          <DescriptionWrapper>
            <Text fontSize="h3" fontWeight="bold" lineHeight="large" color={theme.v2.text.headingPrimary}>
              {price?.toString() ?? ''}
            </Text>
            <Text fontWeight="semi" color={theme.v2.text.headingPrimary}>
              {titleCurrency}
            </Text>
          </DescriptionWrapper>
        </OverviewGraphContent>
      </OverviewGraphContainer>
      <OverviewGraphCards>
        {cards.map((card) => (
          <OverviewGraphCard
            key={card.type}
            totalInvestedAmount={card.totalInvestedAmount?.split(' ')[0]}
            count={formatAmountWithoutDecimals(card.totalBought)}
            type={card.type}
            titleCurrency={titleCurrency}
          />
        ))}
      </OverviewGraphCards>
    </StyledPortfolioCard>
  );
};
