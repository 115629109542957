import styled from 'styled-components';

import { RemSize } from '../../../../theme';

export const IconWrapper = styled.div<{
  $backgroundColor: string;
}>`
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.xl};
`;

export const TextWrapper = styled.div<{ $gap: RemSize }>`
  height: 100%;
  display: flex;
  gap: ${({ $gap }) => $gap};
  flex-direction: column;
`;

export const CardWrapper = styled.div`
  display: flex;
  width: auto;
  gap: 1rem;
`;

export const TextContent = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const TitleLoadingWrapper = styled.div`
  padding: 0.25rem 0;
`;

export const SubtitleLoadingWrapper = styled.div`
  padding-top: 0.5rem;
`;
