import styled from 'styled-components';

export const TextWrapper = styled.div<{ $isLocked: boolean }>`
  height: 100%;
  width: calc(100% - 1.25rem);
  position: relative;
  transform: translate(${({ $isLocked }) => ($isLocked ? '1rem' : '1.25rem')}, -100%);
  border: 1px solid transparent;
  pointer-events: none;
  transition: transform 0.4s;
  user-select: none;
`;

export const StyledText = styled.p<{ $isIconShown?: boolean; $isAlertIconShown?: boolean }>`
  color: ${({ theme }) => theme.v2.text.disabled};
  position: absolute;
  left: ${({ $isIconShown, $isAlertIconShown }) => ($isIconShown || $isAlertIconShown ? '2.25rem' : '0')};
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  background-color: transparent;
  transition: 0.2s cubic-bezier(1, 1, 0.62, 0.89);
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 1.25rem);
  text-overflow: ellipsis;
  margin: 0;
  z-index: 1;
`;

export const TextCurrencyWrapper = styled.div`
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const StartIcoTooltipWrapper = styled.div`
  top: 1.063rem;
  left: 1.113rem;
  position: absolute;
  z-index: 9999;
  opacity: 0;
`;
