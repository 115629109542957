import { BaseCardContainer } from './baseCard.styles';
import { BaseCardProps } from './types';

export const BaseCard = ({
  palette = 'neutrals',
  borderColor = 'hue200',
  borderRadius = 'large',
  backgroundColor = 'hue0',
  padding = '1rem',
  hoverPalette = 'neutrals',
  activePalette = 'primary',
  width,
  height = '100%',
  disableStateEffects = false,
  removeBackground = false,
  removeBorder = false,
  onClick,
  children,
  className,
  isSelected = false,
  borderBox = false,
  hasCursorPointer = false,
  onMouseLeave,
  onMouseEnter,
  allowOverflow = false,
  removeBackgroundHover = false,
  hasCaseCardShadow = false,
  withoutBackground = false,
  removeOverflow = false,
  override,
}: BaseCardProps) => {
  return (
    <BaseCardContainer
      $palette={palette}
      $borderColor={borderColor}
      $borderRadius={borderRadius}
      $backgroundColor={backgroundColor}
      $padding={padding}
      $hoverPalette={hoverPalette}
      $activePalette={activePalette}
      $width={width ?? '100%'}
      $height={height}
      $disableStateEffects={disableStateEffects}
      $removeBackground={removeBackground}
      $removeBorder={removeBorder}
      $isSelected={isSelected}
      $hasCursorPointer={hasCursorPointer}
      onClick={onClick}
      className={className}
      $borderBox={borderBox}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      $allowOverflow={allowOverflow}
      $removeBackgroundHover={removeBackgroundHover}
      $hasCaseCardShadow={hasCaseCardShadow}
      $withoutBackground={withoutBackground}
      $removeOverflow={removeOverflow}
      $override={override}
    >
      {children}
    </BaseCardContainer>
  );
};
