import { FieldValues, Path, FieldError, Controller, Control } from 'react-hook-form';
import styled from 'styled-components';

import { DatePicker } from '../../../components';
import { DatePickerProps } from '../../atoms/date-picker/types';

export const StyledContainer = styled.div<{ $fill?: boolean }>`
  margin-bottom: 1.25rem;
  max-width: ${({ $fill }) => ($fill ? '100%' : '28rem')};
  width: 100%;
  height: 3.5rem;
  margin-top: 0.5rem;
  position: relative;
`;

export type FormDatePickerProps<T extends FieldValues> = {
  name: Path<T>;
  error?: FieldError | undefined;
  control: Control<T>;
  fill?: boolean;
  containerId?: string;
} & Omit<DatePickerProps, 'name' | 'error' | 'onChange' | 'selectedDate'>;

export const FormDatePicker = <T extends FieldValues>({
  name,
  error,
  control,
  fill,
  containerId,
  ...datePickerProps
}: FormDatePickerProps<T>) => {
  return (
    <StyledContainer $fill={fill}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <DatePicker
            onChange={field.onChange}
            error={error?.message}
            selectedDate={field.value}
            containerId={containerId}
            {...datePickerProps}
          />
        )}
      />
    </StyledContainer>
  );
};
