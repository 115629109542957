import { FileCheck2, ThemedIcon } from '@metaswiss/ui-kit';
import { FileEditIcon } from '@metaswiss/ui-kit/src/iconography/FileEditIcon';
import { FileLockIcon } from '@metaswiss/ui-kit/src/iconography/FileLockIcon';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { SettingsCard } from '../../../../components/settings-card/SettingsCard';
import { DocumentStatus } from '../../../../enums/documentStatus.enum';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';

import { CardWrapper } from './styles/verification.styles';

type Props = {
  proofOfDomicileStatus: DocumentStatus;
  signatureStatus: DocumentStatus;
  commercialRegisterStatus: DocumentStatus;
  setIsDomicileModalOpen: (isDomicileModalOpen: boolean) => void;
  setIsSignatureModalOpen: (isSignatureModalOpen: boolean) => void;
  setIsCommercialRegisterModalOpen: (isCommercialRegisterModalOpen: boolean) => void;
};

export const CorporateVerification: FC<Props> = ({
  setIsDomicileModalOpen,
  proofOfDomicileStatus,
  setIsSignatureModalOpen,
  signatureStatus,
  setIsCommercialRegisterModalOpen,
  commercialRegisterStatus,
}) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  return (
    <CardWrapper>
      <SettingsCard
        title={textTranslation('account.commercialRegister')}
        icon={
          <ThemedIcon icon={FileLockIcon} customStrokeColor={theme.v2.text.action} palette={'primary'} size={'xl'} />
        }
        selectedOptionText={textTranslation('account.requiredDocs')}
        displaySelectedOptionLabel={textTranslation(`account.commercialRegisterTitle`)}
        onClick={() => setIsCommercialRegisterModalOpen(commercialRegisterStatus !== DocumentStatus.VERIFIED)}
        documentStatus={commercialRegisterStatus}
        documentStatusTranslation={textTranslation(`account.${commercialRegisterStatus}`)}
        width={'21.75rem'}
        isHoverActive={commercialRegisterStatus !== DocumentStatus.VERIFIED}
        disabled={commercialRegisterStatus === DocumentStatus.SUBMITTED}
      />
      <SettingsCard
        title={textTranslation('account.proofDomicileTitle')}
        icon={<ThemedIcon icon={FileCheck2} customStrokeColor={theme.v2.text.action} palette={'primary'} size={'xl'} />}
        selectedOptionText={textTranslation('account.requiredDocs')}
        displaySelectedOptionLabel={textTranslation(`account.utilityBillOrSimilar`)}
        onClick={() => setIsDomicileModalOpen(proofOfDomicileStatus !== DocumentStatus.VERIFIED)}
        documentStatus={proofOfDomicileStatus}
        documentStatusTranslation={textTranslation(`account.${proofOfDomicileStatus}`)}
        width={'21.75rem'}
        isHoverActive={proofOfDomicileStatus !== DocumentStatus.VERIFIED}
        disabled={proofOfDomicileStatus === DocumentStatus.SUBMITTED}
      />

      <SettingsCard
        title={textTranslation('account.signature')}
        icon={
          <ThemedIcon icon={FileEditIcon} customStrokeColor={theme.v2.text.action} palette={'primary'} size={'xl'} />
        }
        selectedOptionText={textTranslation('account.requiredDocs')}
        displaySelectedOptionLabel={textTranslation(`account.signatureVerficationTitle`)}
        onClick={() => setIsSignatureModalOpen(signatureStatus !== DocumentStatus.VERIFIED)}
        documentStatus={signatureStatus}
        documentStatusTranslation={textTranslation(`account.${signatureStatus}`)}
        width={'21.75rem'}
        isHoverActive={signatureStatus !== DocumentStatus.VERIFIED}
        disabled={signatureStatus === DocumentStatus.SUBMITTED}
      />
    </CardWrapper>
  );
};
