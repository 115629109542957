import { styled } from 'styled-components';

export const CaseChatWrapper = styled.div`
  bottom: 0;
  max-width: 77.5rem;
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.large};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  padding: 1rem;
`;

export const DocumentsWrapper = styled.div`
  padding-bottom: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
  overflow-x: auto;
  display: flex;
  gap: 1rem;
`;

export const SingleDocumentWrapper = styled.div`
  max-width: 20rem;
  flex-shrink: 0;
`;

export const InputUploadWrapper = styled.div`
  flex: 1;
`;

export const TextareaWrapper = styled.div`
  margin-top: 0.625rem;
  width: 100%;
`;

export const ChatTextarea = styled.textarea`
  border: none;
  height: auto;
  resize: none;
  line-height: ${({ theme }) => theme.typography.lineHeight.base};
  width: 100%;
  max-height: 6rem;
  &:focus-visible {
    cursor: text;
  }
`;

export const AttachDocumentsInput = styled.input`
  display: none;
`;
