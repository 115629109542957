import styled from 'styled-components';

import { activeInputTextStyles, activeStylesInputFont, errorActiveStateStyles, errorStateStyles } from './inputStyles';
import { StyledField } from './styledField';
import { StyledLegend } from './styledLegend';
import { StyledText, TextWrapper } from './styledText';

type StyledInputProps = {
  $isActiveInput: boolean;
  $isError: boolean;
  $isIconShown?: boolean;
  $isIconEndShown?: boolean;
  $disableFocus: boolean;
  $customErrorExists?: boolean;
  $isPassword?: boolean;
  $isVisibleLabel?: boolean;
  $isLocked?: boolean;
  $widthOffset?: number;
};

export const StyledInput = styled.input<StyledInputProps>`
  font-size: ${({ theme }) => theme.typography.sizes.base};
  border: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  background-color: ${({ theme, $isLocked }) => ($isLocked ? 'transparent' : theme.v2.surface.field)};
  color: ${({ theme }) => theme.v2.text.headingPrimary};
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  overflow: hidden;
  padding-right: ${({ theme, $isIconEndShown, $isPassword, $widthOffset }) =>
    $widthOffset
      ? `calc(${$widthOffset}px + ${theme.v2.spacing['1.25']})`
      : $isIconEndShown || $isPassword
        ? '3.5rem'
        : theme.v2.spacing['1.25']};
  padding-left: ${({ $isIconShown, theme }) => ($isIconShown ? '3.5rem' : theme.v2.spacing['1.25'])};
  ${({ $isActiveInput, $isVisibleLabel }) => $isActiveInput && activeStylesInputFont($isVisibleLabel)}
  ${({ $isError, $customErrorExists, $isActiveInput }) =>
    ($isError || $customErrorExists) && $isActiveInput
      ? errorActiveStateStyles
      : $isError || $customErrorExists
        ? errorStateStyles
        : undefined}
    transition: padding 0.4s;

  &:disabled {
    border-color: ${({ theme, $disableFocus }) => !$disableFocus && theme.v2.border.primaryHover};
    background-color: ${({ theme, $disableFocus }) => $disableFocus && theme.v2.surface.disabled};
    pointer-events: none;
    color: ${({ theme, $disableFocus }) => $disableFocus && theme.v2.text.disabled};

    ~ ${TextWrapper} {
      ${StyledText} {
        ${({ $disableFocus }) => !$disableFocus && activeInputTextStyles()};
        color: ${({ theme, $disableFocus }) => !$disableFocus && theme.v2.text.disabled};
      }
    }

    ~ ${StyledField} {
      border-color: ${({ theme }) => theme.v2.border.primaryHover};

      * {
        stroke: ${({ theme, $disableFocus }) =>
          $disableFocus ? theme.v2.text.disabled : theme.v2.text.headingPrimary};
      }

      ${StyledLegend} {
        opacity: 1;
        max-width: ${({ $disableFocus }) => !$disableFocus && '100%'};
      }
    }
  }

  &:focus-visible {
    border: 0;
    outline: none;
    cursor: text;
    font-weight: ${({ theme }) => theme.typography.weights.bold};

    ~ div {
      ${StyledText} {
        color: ${({ theme, $isError, $customErrorExists }) =>
          $isError || $customErrorExists ? theme.v2.text.error : theme.v2.text.information};
        ${({ $isVisibleLabel }) => activeInputTextStyles($isVisibleLabel)};
      }
    }

    ~ ${StyledField} {
      border: 1px solid
        ${({ theme, $isError, $customErrorExists }) =>
          !$isError && !$customErrorExists ? theme.v2.border.information : theme.v2.border.error};

      ${StyledLegend} {
        max-width: ${({ $isVisibleLabel }) => ($isVisibleLabel ? '100%' : '0')};
        opacity: ${({ $isVisibleLabel }) => ($isVisibleLabel ? '1' : '0')};
      }
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.v2.surface.field} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.v2.text.headingPrimary};

    ~ div {
      ${StyledText} {
        color: ${({ theme, $isError, $customErrorExists }) =>
          $isError || $customErrorExists ? theme.v2.text.error : theme.v2.text.information};
        ${activeInputTextStyles()}
      }
    }

    ~ ${StyledField} {
      background: transparent;
      border: 1px solid
        ${({ theme, $isError, $customErrorExists }) => !$isError && !$customErrorExists && theme.v2.border.primary};

      ${StyledLegend} {
        max-width: 100%;
        opacity: 1;
      }
    }
  }
`;
