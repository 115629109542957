import { Flex, Text } from '@metaswiss/ui-kit';
import styled, { useTheme } from 'styled-components';

import { RemSize, TypographyTextSize, TypographyWeight } from '../../../theme/theme';
import { HiddenRadio } from '../../atoms/radio-button/styles/hiddenRadio';
import { RadioContainer } from '../../atoms/radio-button/styles/radioContainer';
import { RadioLabel } from '../../atoms/radio-button/styles/radioLabel';

export type RadioOption<T> = {
  label: string;
  value: T;
};

type RadioButtonV2Props<T> = {
  onSelect: (selected: RadioOption<T>) => void;
  isSelected: boolean;
  error?: boolean;
  size?: 'medium' | 'large';
  radioOption: RadioOption<T>;
  disabled?: boolean;
  zIndex?: boolean;
  textSize?: TypographyTextSize;
  textWeight?: TypographyWeight;
};

export const RadioButtonV2 = <T,>({
  onSelect,
  isSelected,
  size = 'medium',
  radioOption,
  error,
  disabled = false,
  zIndex = false,
  textSize = 'base',
  textWeight = 'reg',
}: RadioButtonV2Props<T>) => {
  const theme = useTheme();
  return (
    <RadioContainer
      $disabled={disabled}
      onClick={() => {
        onSelect(radioOption);
      }}
    >
      <RadioLabel
        $zIndex={zIndex}
        $size={theme.icon.sizes[size]}
        $checked={isSelected}
        $disabled={disabled}
        $isError={error}
      >
        <HiddenRadio $size={theme.icon.sizes[size]} />
      </RadioLabel>
      {radioOption.label && (
        <Text fontSize={textSize} fontWeight={textWeight} color={theme.v2.text.bodyPrimary}>
          {radioOption.label}
        </Text>
      )}
    </RadioContainer>
  );
};

export type RadioButtonGroupV2Props<T> = {
  options: RadioOption<T>[];
  selected: T;
  onSelect: (value: T) => void;
  error?: boolean;
  flexDirection?: 'column' | 'row' | 'column-reverse';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'normal';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  wrap?: 'wrap' | 'nowrap';
  gap?: RemSize;
};

const Wrapper = styled.div`
  &:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.v2.border.primary};
    padding-left: 1.5rem;
  }
`;

export const RadioButtonGroupV2 = <T,>({
  options,
  selected,
  onSelect,
  error,
  ...props
}: RadioButtonGroupV2Props<T>) => {
  return (
    <Flex {...props}>
      {options.map((option) => (
        <Wrapper key={option.label} style={{ marginBottom: '1.5rem' }}>
          <RadioButtonV2<T>
            onSelect={() => {
              if (selected == option.value) {
                return;
              }
              onSelect(option.value);
            }}
            isSelected={selected === option.value}
            radioOption={option}
            error={error}
          />
        </Wrapper>
      ))}
    </Flex>
  );
};
