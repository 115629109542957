import { ProductType } from '../../../../../enums/productType.enum';
import { TenantPermissions } from '../../../../../enums/tenantPermissions';
import { routes } from '../../../../../router/routes';
import { NavigationTabProps } from '../../../../../shared/types/tabProps';

export const offeringTabs: NavigationTabProps[] = [
  {
    label: ProductType.NFT,
    route: routes.offering.smartCollectibles.root,
    value: 'smartCollectibles',
    isScrollablePage: true,
    hasInfiniteScroll: true,
    permission: TenantPermissions.NFT,
  },
  {
    label: ProductType.ACTIONS,
    route: routes.offering.smartShares.root,
    value: 'smartShares',
    permission: TenantPermissions.SHARE,
  },
  {
    label: ProductType.BOND_PAPERS,
    route: routes.offering.smartBonds.root,
    value: 'smartBonds',
    permission: TenantPermissions.BOND,
  },
];
