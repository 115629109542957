import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { IconProps, ThemedIconProps } from '../../../iconography/iconProps';

import { ThemedIconContainer } from './styles/iconContainer';

type ThemeIconProps = ThemedIconProps & {
  icon: React.FC<IconProps> | (() => ReactElement);
  hasBorderRadius?: boolean;
  onClick?: () => void;
};

export const ThemedIcon = ({
  icon: Icon,
  palette = 'neutrals',
  color = 'none',
  strokeColor = 'none',
  size = 'medium',
  hasBorderRadius = false,
  customColor,
  customStrokeColor,
  onClick,
}: ThemeIconProps) => {
  const theme = useTheme();

  const iconColor: string = color !== 'none' ? theme.colors[palette][color] : 'none';

  const iconStrokeColor: string = strokeColor !== 'none' ? theme.colors[palette][strokeColor] : 'none';

  const iconSize = size !== 'full' ? theme.icon.sizes[size] : '100%';

  let SelectedIcon: ReactElement<IconProps>;

  if (Icon.length) {
    SelectedIcon = (
      <Icon
        color={customColor || iconColor}
        strokeColor={customStrokeColor || iconStrokeColor}
        hasBorderRadius={hasBorderRadius}
      />
    );
  } else {
    const renderIcon = Icon as () => ReactElement;
    SelectedIcon = renderIcon();
  }

  return (
    <ThemedIconContainer onClick={onClick} $size={iconSize}>
      {SelectedIcon}
    </ThemedIconContainer>
  );
};
