import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from '../../../iconography/iconProps';
import { ThemedIcon } from '../../molecules/theme-icon';
import { Text } from '../text';

import { StatusContainer } from './status.styles';

export type StatusType = 'draft' | 'inReview' | 'approved' | 'error' | 'pending';

type StatusProps = {
  status: StatusType;
  text: string;
  icon?: FC<IconProps>;
  textAlign?: 'start' | 'end' | 'center' | 'justify';
};

export const Status: FC<StatusProps> = ({ status, text, icon, textAlign }) => {
  const theme = useTheme();
  const colors = useMemo(() => {
    const statusColors = {
      draft: {
        background: theme.v2.surface.tertiary,
        text: theme.v2.text.headingPrimary,
        icon: theme.v2.text.headingPrimary,
      },
      inReview: {
        background: theme.v2.surface.action2Light,
        text: theme.v2.text.action2,
        icon: theme.v2.text.action2,
      },
      approved: {
        background: theme.v2.surface.successLight,
        text: theme.v2.text.success,
        icon: theme.v2.text.success,
      },
      error: {
        background: theme.v2.surface.errorLight,
        text: theme.v2.text.error,
        icon: theme.v2.text.error,
      },
      pending: {
        background: theme.v2.surface.warningLight,
        text: theme.v2.text.warning,
        icon: theme.v2.text.warning,
      },
    };
    return statusColors[status];
  }, [theme, status]);

  if (!colors) {
    return null;
  }
  return (
    <StatusContainer $backgroundColor={colors.background}>
      {icon && <ThemedIcon icon={icon} customStrokeColor={colors.icon} size="medium" />}
      <Text textAlign={textAlign} color={colors.text} fontWeight="semi" fontSize="xsm" lineHeight="extraSmall">
        {text}
      </Text>
    </StatusContainer>
  );
};
