import styled, { css } from 'styled-components';

import { CardContainerProps } from './types';

export const BaseCardContainer = styled.div<CardContainerProps>`
  background-color: ${({ theme, $palette, $backgroundColor, $removeBackground }) =>
    $removeBackground ? 'transparent' : theme.colors[$palette][$backgroundColor]};
  border: 1px solid
    ${({ theme, $palette, $borderColor, $isSelected, $activePalette, $removeBorder }) =>
      $removeBorder
        ? 'none'
        : $isSelected
          ? theme.colors[$activePalette].hue200
          : theme.colors[$palette][$borderColor]};
  border-radius: ${({ theme, $borderRadius }) => theme.dimensions.borderRadii[$borderRadius]};
  padding: ${({ $padding }) => $padding};
  width: ${({ $width }) => $width};
  cursor: ${({ $hasCursorPointer }) => ($hasCursorPointer ? 'pointer' : 'auto')};
  position: relative;
  height: ${({ $height }) => $height};
  transition: all 0.2s ease-in-out;

  overflow-y: ${({ $allowOverflow, $removeOverflow }) =>
    $removeOverflow ? 'visible' : $allowOverflow ? 'auto' : 'hidden'};

  &:hover {
    ${({ theme, $disableStateEffects, $hoverPalette, $isSelected, $activePalette, $removeBackgroundHover }) =>
      !$disableStateEffects &&
      css`
        background-color: ${!$removeBackgroundHover && theme.colors[$hoverPalette].hue25};
        border-color: ${$isSelected ? theme.colors[$activePalette].hue200 : theme.colors[$hoverPalette].hue200};
        cursor: pointer;
      `}

    box-shadow: ${({ $borderBox, $hasCaseCardShadow }) =>
      $borderBox
        ? '0 0 1rem 0 rgba(0, 0, 0, 0.02), 0 0.0625rem 0 0 rgba(0, 0, 0, 0.02), 0 0.625rem 1.5rem 0 rgba(0, 0, 0, 0.02);'
        : $hasCaseCardShadow
          ? '0px 0px 16px 0px #00000005, 0px 1px 0px 0px #00000005, 0px 10px 24px 0px #00000005;'
          : '0rem'};
  }

  &:active {
    ${({ theme, $disableStateEffects, $activePalette, $withoutBackground }) =>
      !$disableStateEffects &&
      css`
        background-color: ${!$withoutBackground && theme.colors[$activePalette].hue0};
        border-color: ${theme.colors[$activePalette].hue300};
      `}
  }

  ${({ $override, $isSelected }) =>
    $override &&
    `
      ${$override.backgroundColor && `background-color: ${$override.backgroundColor};`}
      ${
        $override.borderColor &&
        `border-color: ${
          $isSelected && $override.activeBorderColor ? $override.activeBorderColor : $override.borderColor
        };`
      } 
      ${$override.borderRadius && `border-radius: ${$override.borderRadius};`}
      
      &:hover {
        ${$override.hoverBackgroundColor && `background-color: ${$override.hoverBackgroundColor};`}
        ${$override.hoverBorderColor && !$isSelected && `border-color: ${$override.hoverBorderColor};`}
      }

      &:active {
        ${$override.activeBackgroundColor && `background-color: ${$override.activeBackgroundColor};`}
        ${$override.activeBorderColor && `border-color: ${$override.activeBorderColor};`}
      }
    `};
`;
