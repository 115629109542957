import { ErrorPageIcon } from '../../../iconography/ErrorPageIcon';
import { ResetGlobalStyles } from '../../../shared/styles/globalReset.styles';

import {
  ErrorButton,
  ErrorDataStatusWrapper,
  ErrorDescription,
  ErrorIconWrapper,
  ErrorTextsWrapper,
  ErrorTitle,
} from './initialErrorDataStatus.styles';

type InitialErrorDataStatusProps = {
  title: string;
  description: string;
  buttonText: string;
  onTryAgain: () => void;
};

export const InitialErrorDataStatus = ({ title, description, buttonText, onTryAgain }: InitialErrorDataStatusProps) => {
  return (
    <>
      <ResetGlobalStyles />
      <ErrorDataStatusWrapper>
        <ErrorIconWrapper>
          <ErrorPageIcon />
        </ErrorIconWrapper>
        <ErrorTextsWrapper>
          <ErrorTitle>{title}</ErrorTitle>
          <ErrorDescription>{description}</ErrorDescription>
        </ErrorTextsWrapper>
        <ErrorButton onClick={onTryAgain}>{buttonText}</ErrorButton>
      </ErrorDataStatusWrapper>
    </>
  );
};
