import { ResourceInfoVariant, Skeleton, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { ResourceInfoSize } from '../../..';
import { PropertyProps } from '../../../../components/molecules/resource-details/types';

import {
  CardWrapper,
  IconWrapper,
  SubtitleLoadingWrapper,
  TextContent,
  TextWrapper,
  TitleLoadingWrapper,
} from './resourceInfo.styles';
import { ResourceInfoProps } from './types';

type IconStyleProps = {
  iconBackgroundColor: string;
  iconColor: string;
};

export const ResourceInfoV2: FC<ResourceInfoProps> = ({
  icon,
  title,
  titleColor,
  subTitle,
  subTitleColor,
  variant,
  size = ResourceInfoSize.LARGE,
  isLightContent = false,
  renderSubTitleComponent,
  gap,
  isLoading,
}) => {
  const theme = useTheme();
  const { titleProps, subTitleProps } = getSize(size);

  const { iconBackgroundColor, iconColor } = useMemo<IconStyleProps>(() => {
    switch (variant) {
      case ResourceInfoVariant.NEUTRALS:
        return {
          iconBackgroundColor: theme.v2.surface.tertiary,
          iconColor: theme.v2.text.headingPrimary,
        };
      case ResourceInfoVariant.DISABLED:
        return {
          iconBackgroundColor: theme.v2.surface.tertiary,
          iconColor: theme.v2.icon.neutral,
        };
      case ResourceInfoVariant.ERROR:
        return {
          iconBackgroundColor: theme.v2.surface.errorLight,
          iconColor: theme.v2.icon.error,
        };
      case ResourceInfoVariant.PRIMARY:
        return {
          iconBackgroundColor: theme.v2.colors.primary25,
          iconColor: theme.v2.icon.primary,
        };
      case ResourceInfoVariant.SECONDARY:
        return {
          iconBackgroundColor: theme.v2.colors.secondary25,
          iconColor: theme.v2.icon.secondary,
        };
      case ResourceInfoVariant.TERTIARY:
        return {
          iconBackgroundColor: theme.v2.colors.tertiary25,
          iconColor: theme.v2.surface.action3,
        };
      case ResourceInfoVariant.SUCCESS:
        return {
          iconBackgroundColor: theme.v2.surface.successLight,
          iconColor: theme.v2.icon.success,
        };
      case ResourceInfoVariant.WARNING:
        return {
          iconBackgroundColor: theme.v2.surface.warningLight,
          iconColor: theme.v2.icon.warning,
        };
      default:
        return {
          iconBackgroundColor: theme.v2.surface.disabled,
          iconColor: theme.v2.surface.disabled,
        };
    }
  }, [variant, theme.v2]);

  return (
    <CardWrapper>
      {isLoading ? (
        <Skeleton width={'3.5rem'} height={'3.5rem'} />
      ) : (
        <IconWrapper $backgroundColor={iconBackgroundColor}>
          <ThemedIcon icon={icon} size={'large'} customStrokeColor={iconColor} />
        </IconWrapper>
      )}
      <TextWrapper $gap={gap || (size === ResourceInfoSize.SMALL ? '0.25rem' : '0rem')}>
        <TextContent>
          {isLoading ? (
            <TitleLoadingWrapper>
              <Skeleton width={'9.75rem'} height={'1.5rem'} />
            </TitleLoadingWrapper>
          ) : (
            <Text
              color={titleColor || (isLightContent ? theme.v2.text.onAction : theme.v2.text.headingPrimary)}
              {...titleProps}
            >
              {title}
            </Text>
          )}
        </TextContent>
        {isLoading ? (
          <SubtitleLoadingWrapper>
            <Skeleton width={'7.5rem'} height={'1rem'} />
          </SubtitleLoadingWrapper>
        ) : (
          renderSubTitleComponent || (
            <Text
              color={subTitleColor || (isLightContent ? theme.v2.text.onAction : theme.v2.text.disabled)}
              {...subTitleProps}
            >
              {subTitle}
            </Text>
          )
        )}
      </TextWrapper>
    </CardWrapper>
  );
};

export const getSize = (
  size: ResourceInfoSize
): {
  titleProps: Partial<PropertyProps>;
  subTitleProps?: Partial<PropertyProps>;
} => {
  switch (size) {
    case ResourceInfoSize.SMALL:
      return {
        titleProps: {
          fontSize: 'base',
          lineHeight: 'base',
          fontWeight: 'bold',
        },
      };
    case ResourceInfoSize.MEDIUM:
      return {
        titleProps: {
          fontSize: 'base',
          lineHeight: 'base',
          fontWeight: 'reg',
        },
        subTitleProps: {
          fontSize: 'lg',
          lineHeight: 'h4',
          fontWeight: 'bold',
        },
      };
    case ResourceInfoSize.MEDIUM_LARGE:
      return {
        titleProps: {
          fontSize: 'lg',
          lineHeight: 'h4',
          fontWeight: 'bold',
        },
      };
    case ResourceInfoSize.LARGE:
      return {
        titleProps: {
          fontSize: 'h4',
          lineHeight: 'h4',
          fontWeight: 'bold',
        },
      };

    default:
      throw new Error(`Unsupported size: ${size}`);
  }
};
