import styled from 'styled-components';

import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles.tsx';

export const ListCardWrapper = styled.div<{ $isCardClickable: boolean }>`
  transform: scale(1);
  transition: transform 100ms ease-out;
  transform-origin: top;

  &:hover {
    transform: ${({ $isCardClickable }) => $isCardClickable && 'scale(1.015)'};
  }

  &:active {
    transform: scale(1);
  }
`;

export const Container = styled.div`
  display: flex;
  overflow-y: hidden;
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 50%;
  padding-right: 1rem;
  border-right: 1px solid ${({ theme }) => theme.v2.border.primary};
  height: 100%;
`;

export const SlotWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  width: 50%;
`;

export const UserInfo = styled.span`
  display: flex;
  gap: 0.5rem;

  &:first-of-type {
    padding-right: 0.75rem;
    border-right: ${({ theme }) => `1px solid ${theme.v2.border.primary}`};
  }

  &:last-of-type {
    padding-left: 0.75rem;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  height: min-content;
`;

export const ColumnsLayoutContainer = styled.div<{ $gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ $gap }) => $gap || 0}rem;
`;

export const ColumnWrapper = styled.div<{ $gap?: number }>`
  display: flex;
  gap: ${({ $gap }) => $gap || 0.75}rem;
  flex-direction: column;
`;

export const RowWrapper = styled.div<{ $gap?: number }>`
  display: flex;
  gap: ${({ $gap }) => $gap || 0.75}rem;
  flex-direction: row;
`;

export const ProductsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: flex-start;
`;

export const ProductsWrapperV2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: flex-start;
`;

export const Title = styled(TextTemplate)<{ $colorText: string }>`
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${({ $colorText }) => $colorText};

  ${TextTemplate} {
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;

export const UserDateV2 = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

export const StatusLine = styled.div<{ $color: string; $borderWidth: number }>`
  position: absolute;
  top: ${({ $borderWidth }) => -$borderWidth}px;
  right: ${({ $borderWidth }) => -$borderWidth}px;
  height: calc(100% + ${({ $borderWidth }) => $borderWidth * 2}px);
  width: 0.5rem;
  background-color: ${({ $color }) => $color};
  z-index: 0;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  height: 100%;
  gap: 0.5rem;
  flex-direction: column;
`;

export const StatusLabelContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

export const IconWrapper = styled.div<{
  $backgroundColor: string;
}>`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor ?? `${theme.v2.colors.neutral100}26`};
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
`;
