import { TenantThemeAndPermissionsResponse } from '@metaswiss/api';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import ProtectedRoute from '../components/protected-route/ProtectedRoute';
import PublicRoute from '../components/public-route/PublicRoute';
import { ProtectedLayout } from '../layouts/protected-layout/ProtectedLayout';
import { PublicLayout } from '../layouts/public-layout/PublicLayout';
import { VerifyLayout } from '../layouts/verify-layout/VerifyLayout';
import { Error404 } from '../pages/error/error-404/Error404';
import { Maintenance } from '../pages/maintenance-mode/Maintenance';
import { Account } from '../pages/protected/account/Account';
import { AccountManagement } from '../pages/protected/account/account-management/AccountManagement';
import { AccountManagementOveriew } from '../pages/protected/account/account-management/account-management-overview/AccountManagementOverview';
import { DeleteAccount } from '../pages/protected/account/account-management/delete-account/DeleteAccount';
import { Address } from '../pages/protected/account/address/Address';
import { BankInformation } from '../pages/protected/account/bank-information/BankInformation';
import { Overview } from '../pages/protected/account/overview/Overview';
import { Profile } from '../pages/protected/account/profile/Profile';
import { Transactions } from '../pages/protected/account/transactions/Transactions';
import { AllTransactions } from '../pages/protected/account/transactions/all-transactions/AllTransactions';
import { SingleTransaction } from '../pages/protected/account/transactions/single-transaction/SingleTransaction';
import { Verification } from '../pages/protected/account/verification/Verification';
import { Cases } from '../pages/protected/cases/Cases';
import { ActiveCases } from '../pages/protected/cases/active-cases/ActiveCases';
import { ActiveCasesRoot } from '../pages/protected/cases/active-cases/ActiveCasesRoot';
import { CaseDetails } from '../pages/protected/cases/case-details/CaseDetails';
import { CaseHistory } from '../pages/protected/cases/cases-history/CaseHistory';
import { CaseHistoryRoot } from '../pages/protected/cases/cases-history/CaseHistoryRoot';
import { Home } from '../pages/protected/home/Home';
import { AboutUs } from '../pages/protected/home/about-us/AboutUs';
import { Dashboard } from '../pages/protected/home/dashboard/Dashboard';
import { Newsletter } from '../pages/protected/home/newsletter/Newsletter';
import { Settings } from '../pages/protected/home/settings/Settings';
import { Offering } from '../pages/protected/offering/Offering';
import { SmartBonds } from '../pages/protected/offering/smart-bonds/SmartBonds';
import { AllBonds } from '../pages/protected/offering/smart-bonds/all-bonds/AllBonds';
import SingleBond from '../pages/protected/offering/smart-bonds/single-bond/SingleBond';
import { SmartCollectiblesOffering } from '../pages/protected/offering/smart-collectibles/SmartCollectiblesOffering';
import { Collectibles } from '../pages/protected/offering/smart-collectibles/collectibles/Collectibles';
import { Collections } from '../pages/protected/offering/smart-collectibles/collections/Collections';
import { SingleCollection } from '../pages/protected/offering/smart-collectibles/single-collection/SingleCollection';
import { SingleView } from '../pages/protected/offering/smart-collectibles/single-view/SingleView';
import { SmartShares } from '../pages/protected/offering/smart-shares/SmartShares';
import { SharesHandler } from '../pages/protected/offering/smart-shares/shares-handler/SharesHandler';
import { SingleShareHandler } from '../pages/protected/offering/smart-shares/single-share/SingleShare';
import { BasePayment } from '../pages/protected/payment/BasePayment';
import { Portfolio } from '../pages/protected/portfolio/Portfolio';
import { Overview as OverviewPortfolio } from '../pages/protected/portfolio/overview/Overview';
import { BondsPortfolio } from '../pages/protected/portfolio/smart-bonds/BondsPortfolio';
import { SmartBonds as SmartBondsPortfolio } from '../pages/protected/portfolio/smart-bonds/SmartBonds';
import { BondAssetDetails } from '../pages/protected/portfolio/smart-bonds/asset-details/BondAssetDetails';
import { SmartBondHistory } from '../pages/protected/portfolio/smart-bonds/history/SmartBondHistory';
import { SmartCollectibles } from '../pages/protected/portfolio/smart-collectibles/SmartCollectibles';
import { SmartCollectiblesPortfolio } from '../pages/protected/portfolio/smart-collectibles/SmartCollectiblesPortfolio';
import { CollectibleAssetDetails } from '../pages/protected/portfolio/smart-collectibles/collectible-asset-details/CollectibleAssetDetails';
import { CollectibleHistory } from '../pages/protected/portfolio/smart-collectibles/collectible-history/CollectibleHistory';
import { SmartSharesPortfolio } from '../pages/protected/portfolio/smart-shares/SmartShares';
import { SmartSharesRoot } from '../pages/protected/portfolio/smart-shares/SmartSharesRoot';
import { ShareDetails } from '../pages/protected/portfolio/smart-shares/share-details/ShareDetails';
import { SharesHistory } from '../pages/protected/portfolio/smart-shares/shares-history/SharesHistory';
import { ChangePassword } from '../pages/public/change-password/ChangePassword';
import { ForgotPassword } from '../pages/public/forgot-password/ForgotPassword';
import { Login } from '../pages/public/login/Login';
import { RegistrationFailed } from '../pages/public/register/steps/registration-failed/RegistrationFailed';
import { RegistrationSuccess } from '../pages/public/register/steps/registration-success/RegistrationSuccess';
import { SetRegistrationType } from '../pages/public/register/steps/set-registration-type/SetRegistrationType';
import { SetUserTypeRegistration } from '../pages/public/register/steps/set-user-type-registration/SetUserTypeRegistration';
import { RegistrationWizardSteps } from '../pages/public/register/steps/wizard-step/RegistrationWizardSteps';
import { ExpiredLink } from '../pages/public/reset-password/ExpiredLink';
import { ResetPassword } from '../pages/public/reset-password/ResetPassword';

import { routes } from './routes';

export const router = (permissions: TenantThemeAndPermissionsResponse['permissions']) =>
  createBrowserRouter(
    createRoutesFromElements(
      <>
        {/* Public Routes */}
        <Route
          path={routes.auth}
          element={
            <PublicRoute>
              <PublicLayout />
            </PublicRoute>
          }
        >
          <Route index element={<Login />} />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.changePassword} element={<ChangePassword />} />
          <Route path={routes.resetPassword} element={<ResetPassword />} />
          <Route path={routes.register.root}>
            <Route index element={<SetUserTypeRegistration />} />
            <Route path={routes.register.chooseTypeRegistration} element={<SetRegistrationType />} />
            <Route path={routes.register.registrationSteps} element={<RegistrationWizardSteps />} />
            <Route path={routes.register.registerSuccess} element={<RegistrationSuccess />} />
            <Route path={routes.register.registerFailed} element={<RegistrationFailed />} />
          </Route>
          <Route path={routes.forgotPassword} element={<ForgotPassword />} />
        </Route>

        {/* Protected Routes */}
        <Route
          path={routes.root}
          element={
            <ProtectedRoute>
              <ProtectedLayout />
            </ProtectedRoute>
          }
        >
          <Route path={routes.account.root} element={<Account />}>
            <Route path={routes.account.overview} element={<Overview />} />
            <Route path={routes.account.profile} element={<Profile />} />
            <Route path={routes.account.accountManagement.root} element={<AccountManagement />}>
              <Route index element={<AccountManagementOveriew />} />
              <Route path={routes.account.accountManagement.deleteAccount} element={<DeleteAccount />} />
            </Route>
            <Route path={routes.account.address} element={<Address />} />
            <Route path={routes.account.verification} element={<Verification />} />
            <Route path={routes.account.transactions.root} element={<Transactions />}>
              <Route index path={routes.account.transactions.root} element={<AllTransactions />} />
              <Route path={routes.account.transactions.singleTransaction} element={<SingleTransaction />} />
            </Route>
            <Route path={routes.account.bankInformation} element={<BankInformation />} />
          </Route>
          <Route index element={<Dashboard />} />
          <Route path={routes.home.root} element={<Home />}>
            <Route index element={<Dashboard />} />
            <Route path={routes.home.newsletter} element={<Newsletter />} />
            <Route path={routes.home.aboutUs} element={<AboutUs />} />
          </Route>
          <Route path={routes.settings} element={<Settings />} />
          <Route path={routes.offering.root} element={<Offering />}>
            <Route index element={<Offering />} />
            {permissions.share && (
              <Route path={routes.offering.smartShares.root} element={<SmartShares />}>
                <Route path={routes.offering.smartShares.root} element={<SharesHandler />} />
                <Route path={routes.offering.smartShares.singleView} element={<SingleShareHandler />} />
              </Route>
            )}
            {permissions.nft && (
              <Route path={routes.offering.smartCollectibles.root} element={<SmartCollectiblesOffering />}>
                <Route index element={<Collections />} />
                <Route path={routes.offering.smartCollectibles.collectibles} element={<Collectibles />} />
                <Route path={routes.offering.smartCollectibles.singleCollection} element={<SingleCollection />} />
                <Route path={routes.offering.smartCollectibles.singleView} element={<SingleView />} />
              </Route>
            )}
            {permissions.bond && (
              <>
                <Route path={routes.offering.smartBonds.root} element={<SmartBonds />}>
                  <Route path={routes.offering.smartBonds.root} element={<AllBonds />} />
                  <Route path={routes.offering.smartBonds.singleView} element={<SingleBond />} />
                </Route>
                <Route path={routes.offering.smartBonds.root} element={<SmartBonds />}>
                  <Route path={routes.offering.smartBonds.root} element={<AllBonds />} />
                </Route>
              </>
            )}
          </Route>
          <Route path={routes.cases.root} element={<Cases />}>
            <Route path={routes.cases.active.root} element={<ActiveCasesRoot />}>
              <Route index path={routes.cases.active.root} element={<ActiveCases />} />
              <Route path={routes.cases.active.caseDetails} element={<CaseDetails />} />
            </Route>
            <Route path={routes.cases.history.root} element={<CaseHistoryRoot />}>
              <Route index path={routes.cases.history.root} element={<CaseHistory />} />
              <Route path={routes.cases.history.caseDetails} element={<CaseDetails />} />
            </Route>
          </Route>
          <Route path={routes.portfolio.root} element={<Portfolio />}>
            <Route path={routes.portfolio.overview} element={<OverviewPortfolio />} />
            {permissions.nft && (
              <Route path={routes.portfolio.smartCollectibles.root} element={<SmartCollectiblesPortfolio />}>
                <Route index element={<SmartCollectibles />} />
                <Route path={routes.portfolio.smartCollectibles.nftHistory} element={<CollectibleHistory />} />
                <Route path={routes.portfolio.smartCollectibles.assetDetails} element={<CollectibleAssetDetails />} />
              </Route>
            )}
            {permissions.share && (
              <Route path={routes.portfolio.smartShares.root} element={<SmartSharesRoot />}>
                <Route path={routes.portfolio.smartShares.root} element={<SmartSharesPortfolio />} />
                <Route path={routes.portfolio.smartShares.history} element={<SharesHistory />} />
                <Route path={routes.portfolio.smartShares.details} element={<ShareDetails />} />
              </Route>
            )}
            {permissions.bond && (
              <Route path={routes.portfolio.smartBonds.root} element={<BondsPortfolio />}>
                <Route index path={routes.portfolio.smartBonds.root} element={<SmartBondsPortfolio />} />
                <Route path={routes.portfolio.smartBonds.bondHistory} element={<SmartBondHistory />} />
                <Route path={routes.portfolio.smartBonds.assetDetails} element={<BondAssetDetails />} />
              </Route>
            )}
          </Route>
          <Route path={routes.payment.root}>
            <Route index path={routes.payment.root} element={<BasePayment />} />
            <Route path={routes.payment.success} element={<BasePayment />} />
          </Route>
        </Route>

        <Route path={routes.verify} element={<VerifyLayout />} />
        <Route path={routes.expiredPage} element={<ExpiredLink />} />
        <Route path={'*'} element={<Error404 />} />
      </>
    )
  );

export const routerMaintenance = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={'*'} element={<Maintenance />} />
    </>
  )
);
