import { routes } from '../../../../../router/routes';
import { NavigationTabProps } from '../../../../../shared/types/tabProps';

export const accountTabs: NavigationTabProps[] = [
  {
    label: 'Overview',
    route: routes.account.overview,
    value: 'overview',
  },
  {
    label: 'Profile',
    route: routes.account.profile,
    value: 'profile',
  },
  {
    label: 'Account management',
    route: routes.account.accountManagement.root,
    value: 'accountManagement',
  },
  {
    label: 'Address',
    route: routes.account.address,
    value: 'address',
  },
  {
    label: 'Verification',
    route: routes.account.verification,
    value: 'verification',
  },
  {
    label: 'Bank information',
    route: routes.account.bankInformation,
    value: 'bankInformation',
  },
  {
    label: 'Transactions',
    route: routes.account.transactions.root,
    value: 'transactions',
    isScrollablePage: true,
    hasInfiniteScroll: true,
  },
];
