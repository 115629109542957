import { ScriptableContext } from 'chart.js';
import { DefaultTheme } from 'styled-components';

import { getHexToRgba } from './getHexToRgba';

export const getGradientColor = (
  context: ScriptableContext<'line'>,
  theme: DefaultTheme
): CanvasGradient | 'transparent' => {
  const chart = context.chart;
  if (context.datasetIndex !== 0) {
    return 'transparent';
  }
  const { ctx, chartArea } = chart;
  const gradientSegment = ctx.createLinearGradient(0, chartArea?.top || 0, 0, chartArea?.bottom || 0);
  gradientSegment.addColorStop(0, theme.v2.icon.primary);
  gradientSegment.addColorStop(1, getHexToRgba(theme.v2.icon.primary, 0.04));

  return gradientSegment;
};
