import { IconProps } from './iconProps';

export const TrendUp01Icon: React.FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.6658 4.55263C23.4027 3.81579 24.5973 3.81579 25.3342 4.55263L30.7162 9.93462C31.453 10.6715 31.453 11.8661 30.7162 12.6029L25.3342 17.9849C24.5973 18.7218 23.4027 18.7218 22.6658 17.9849L17.2838 12.6029C16.547 11.8661 16.547 10.6715 17.2839 9.93462L22.6658 4.55263Z"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <path
        d="M35.3971 17.2838C36.1339 16.547 37.3285 16.547 38.0654 17.2838L43.4474 22.6658C44.1842 23.4027 44.1842 24.5973 43.4474 25.3342L38.0654 30.7162C37.3285 31.453 36.1339 31.453 35.3971 30.7162L30.0151 25.3342C29.2782 24.5973 29.2782 23.4027 30.0151 22.6658L35.3971 17.2838Z"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <path
        d="M9.93462 17.2838C10.6715 16.547 11.8661 16.547 12.6029 17.2838L17.9849 22.6658C18.7218 23.4027 18.7218 24.5973 17.9849 25.3342L12.6029 30.7162C11.8661 31.453 10.6715 31.453 9.93462 30.7162L4.55263 25.3342C3.81579 24.5973 3.81579 23.4027 4.55263 22.6658L9.93462 17.2838Z"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <path
        d="M22.6658 30.0151C23.4027 29.2782 24.5973 29.2782 25.3342 30.0151L30.7162 35.3971C31.453 36.1339 31.453 37.3285 30.7162 38.0654L25.3342 43.4474C24.5973 44.1842 23.4027 44.1842 22.6658 43.4474L17.2838 38.0654C16.547 37.3285 16.547 36.1339 17.2838 35.3971L22.6658 30.0151Z"
        stroke={strokeColor}
        strokeWidth="2"
      />
    </svg>
  );
};
