import { mapDate } from '@metaswiss/lib';
import {
  BankIcon,
  BarChart04Icon,
  CoinHandIcon,
  CoinUnbrokenIcon,
  PageStateContainer,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../../api/msApi';
import { AssetDetails } from '../../../../../../components/asset-details/AssetDetails';
import { ItemProps } from '../../../../../../components/asset-details/types';
import { AssetType } from '../../../../../../enums/assetType';
import { ApiResource } from '../../../../../../enums/resource.enum';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../../router/routes';
import { getQueryKey } from '../../../../../../shared/helpers/getQueryKey.helper';
import { LoadingError } from '../components/loading-error/LoadingError';

export const SingleShare = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();
  const { id: shareTransactionId } = useParams();

  const {
    data: shareTransactionData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.SINGLE_TRANSACTION, shareTransactionId),
    queryFn: () => {
      return api.clientTransaction.getClientTransactionById(shareTransactionId as string);
    },
    onError: (error) => {
      const response = error as Response;

      //TODO - test this condition once the backend is updated with 404 status code
      if (response.status === 404) navigate(routes.pageNotFound);
    },
    enabled: !!shareTransactionId,
  });

  const shareItems: ItemProps[] = useMemo(
    () => [
      {
        icon: <ThemedIcon icon={CoinHandIcon} customColor={theme.v2.icon.primary} />,
        subText: textTranslation('account.pricePerShare'),
        text: shareTransactionData?.orderItem?.convertedAmount ?? '',
      },
      {
        icon: <ThemedIcon icon={CoinUnbrokenIcon} customStrokeColor={theme.v2.icon.primary} />,
        subText: textTranslation('account.quantity'),
        text: shareTransactionData?.orderItem?.quantity?.toString() ?? '',
      },
      {
        icon: <ThemedIcon icon={BankIcon} customStrokeColor={theme.v2.icon.primary} />,
        subText: textTranslation('global.paymentMethod'),
        text: textTranslation(`global.${shareTransactionData?.paymentType?.toLowerCase()}`),
      },
      {
        icon: <ThemedIcon icon={BarChart04Icon} customStrokeColor={theme.v2.icon.primary} />,
        subText: textTranslation('global.smartShareCode'),
        text: shareTransactionData?.orderItem?.code ?? '',
      },
    ],
    [shareTransactionData, theme]
  );

  if (isError || isLoading || !shareTransactionData) {
    return <LoadingError isLoading={isLoading} isError={isError} data={shareTransactionData} refetch={refetch} />;
  }

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      showError={true}
      isError={isError || !shareTransactionData}
      onTryAgain={refetch}
      textTranslation={textTranslation}
      showEmptyState={false}
    >
      <AssetDetails
        heading={textTranslation('account.smartShareHeading')}
        amount={shareTransactionData.totalConvertedAmount}
        date={mapDate(shareTransactionData.createdAt)}
        items={shareItems}
        documents={shareTransactionData.invoices}
        assetType={AssetType.TRANSACTIONS}
        transactionStatus={shareTransactionData.status}
      />
    </PageStateContainer>
  );
};
