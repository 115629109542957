import { AnimatePresence, motion, Variants } from 'framer-motion';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ChevronLeftIcon } from '../../../iconography/ChevronLeftIcon';
import { Button } from '../../atoms/button/Button';
import { CircularStepProgress } from '../../atoms/circular-step-progress/CircularStepProgress';
import { Text } from '../../atoms/text/Text';
import { ThemedIcon } from '../../molecules/theme-icon/ThemedIcon';

import {
  AppWizardContainer,
  AppWizardHeader,
  AppWizardHeaderContent,
  AppWizardHeaderTitle,
  ButtonWrapper,
  ChildrenWrapper,
  ContentWrapper,
  IconWrapper,
} from './appWizard.styles';

import { useAppWizard } from '.';

const duration = 0.257;

const contentVariants: Variants = {
  enter: (direction: 'forward' | 'backward') => {
    return {
      position: 'absolute',
      width: '100%',
      x: direction === 'forward' ? '100%' : '-100%',
      opacity: 0,
    };
  },
  center: {
    position: 'relative',
    x: 0,
    opacity: 1,
  },
  exit: (direction: 'forward' | 'backward') => {
    return {
      position: 'absolute',
      width: '100%',
      x: direction === 'forward' ? '-100%' : '100%',
      opacity: 0,
      height: 'auto',
    };
  },
};

const transition = {
  type: 'spring',
  stiffness: 255,
  damping: 14,
  mass: 0.3,
};

const AppWizard: FC = () => {
  const {
    children,
    title,
    subtitle,
    totalSteps,
    currentStep,
    animationDirection,
    onBack,
    onNext,
    buttonText,
    isButtonDisabled,
    isButtonLoading,
    isButtonHidden,
  } = useAppWizard();
  const theme = useTheme();
  const location = useLocation();

  const isInternalTransaction = location.pathname.includes('internal-transactions');

  return (
    <AppWizardContainer>
      <AppWizardHeader>
        <AppWizardHeaderContent>
          <AppWizardHeaderTitle>
            {(!isInternalTransaction || currentStep === 2) && (
              <IconWrapper onClick={onBack}>
                <ThemedIcon icon={ChevronLeftIcon} customStrokeColor={theme.v2.text.headingPrimary} size="large" />
              </IconWrapper>
            )}

            <Text
              fontSize="lg"
              fontWeight="bold"
              textColor="hue700"
              color={theme.v2.text.headingPrimary}
              lineHeight="h4"
            >
              {title || ''}
            </Text>
          </AppWizardHeaderTitle>
          <Text fontSize="base" textColor="hue500" color={theme.v2.text.disabled}>
            {subtitle || ''}
          </Text>
        </AppWizardHeaderContent>
        <CircularStepProgress totalSteps={totalSteps} currentStep={currentStep} animationDuration={duration} />
      </AppWizardHeader>

      <ChildrenWrapper>
        <AnimatePresence initial={false} custom={animationDirection}>
          <motion.div
            key={currentStep}
            initial="enter"
            animate="center"
            exit="exit"
            variants={contentVariants}
            custom={animationDirection}
            transition={transition}
          >
            <ContentWrapper>{children}</ContentWrapper>
            {!isButtonHidden && (
              <ButtonWrapper layout transition={transition}>
                <Button onClick={onNext} text={buttonText} fill loading={isButtonLoading} disabled={isButtonDisabled} />
              </ButtonWrapper>
            )}
          </motion.div>
        </AnimatePresence>
      </ChildrenWrapper>
    </AppWizardContainer>
  );
};

export default AppWizard;
