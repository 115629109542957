import { LoaderContainer } from '@metaswiss/ui-kit';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { stripeKey } from '../../../../../env/stripeVariable';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { usePaymentContext } from '../../context/PaymentContext';

import { StripeCardPaymentForm } from './CreditCardPaymentForm';
import { StripeLoaderWrapper } from './creditCardPaymentForm.styles';

const stripePromise = loadStripe(stripeKey);

export const CreditCardPayment = () => {
  const { clientSecret } = usePaymentContext();
  const { currentLanguage } = useTextTranslation();

  if (!clientSecret)
    return (
      <StripeLoaderWrapper>
        <LoaderContainer />
      </StripeLoaderWrapper>
    );

  return (
    <Elements stripe={stripePromise} options={{ clientSecret, locale: currentLanguage }}>
      <StripeCardPaymentForm />
    </Elements>
  );
};
