import styled from 'styled-components';

export const ErrorDataStatusWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #f9f9fb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorIconWrapper = styled.div`
  max-width: 17rem;
  width: 100%;
  height: auto;
`;

export const ErrorTextsWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  color: #080916;
  margin: 2rem 0 3rem 0;
`;

export const ErrorTitle = styled.h3`
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 0;
`;

export const ErrorDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
`;

export const ErrorButton = styled.button`
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  max-width: 20rem;
  width: 100%;
  padding: 1rem;
  background-color: white;
  border: 1px solid #d3d3e1;
  border-radius: 0.5rem;
  font-weight: bold;
  line-height: 1.5rem;
  font-size: 1rem;

  &:hover {
    background-color: #f9f9fb;
  }

  &:active {
    background-color: #e8e9f1;
  }
`;
