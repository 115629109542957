import { BondFullResponseDTO, NftExtendedV5Response, ShareFullResponseDTO, ShareResponseDTO } from '@metaswiss/api';
import {
  extractNumericDigitsWithDecimals,
  formatAmount,
  formatAmountWithoutCurrencyCode,
  mapCurrencyResponse,
} from '@metaswiss/lib';
import {
  CurrencyCoinCentIcon,
  FormSelectionPopup,
  PriceHolder,
  RadioButton,
  ThemedIcon,
  Text,
  useAppWizard,
  Flex,
} from '@metaswiss/ui-kit';
import { CurrencyChfIcon } from '@metaswiss/ui-kit/src/iconography/CurrencyChfIcon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError, Path, useFormContext } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';

import ControlledForm from '../../../../../components/controlled-form/ControlledForm';
import { ProductType } from '../../../../../enums/productType.enum';
import { AppState, useAppState } from '../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { OneLineElement, SelectionCard } from '../../../../public/register/shared/styles/registrationStyles';
import { usePaymentContext } from '../../context/PaymentContext';
import { createPurchaseStepSchema, PurchaseFormData, purchaseStepSchema } from '../../schemas/purchaseStepSchema';
import { getProductInfo } from '../../utils/getProductInfo';

import { QuantityValidation } from './QuantityValidation';

const PurchaseStepForm = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const [isErrorMinInvestment, setIsErrorMinInvestment] = useState(false);
  const [isErrorMaxInvestment, setIsErrorMaxInvestment] = useState(false);
  const { productType, setQuantity, setCurrency, currency, item, setTotalAmount, quantity, totalAmount } =
    usePaymentContext();
  const { setIsButtonDisabled } = useAppWizard();

  const share = item as ShareResponseDTO;
  const fullShare = item as ShareFullResponseDTO;
  const bond = item as BondFullResponseDTO;

  const shareMinimalInvestment = fullShare.rawMinimalInvestment || Number(share.minimalInvestment);
  const sharePricePerShare = fullShare.rawPricePerShare || extractNumericDigitsWithDecimals(share.pricePerShare);

  const maxInvestmentBond = useMemo(() => {
    const denominationValue = extractNumericDigitsWithDecimals(bond.denomination?.split(' ')[0]);
    return bond.availableBonds * denominationValue;
  }, [bond.availableBonds, bond.denomination]);

  const maxInvestmentShare = share?.availableSharesInRound * sharePricePerShare;

  const maxInvestmentProductLength =
    ProductType.BOND_PAPERS === productType
      ? maxInvestmentBond.toString().length
      : maxInvestmentShare.toString().length;

  const isErrorTotalAmount = useMemo(() => {
    return isErrorMinInvestment || isErrorMaxInvestment;
  }, [isErrorMinInvestment, isErrorMaxInvestment]);

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<PurchaseFormData>();
  const currencyValue = watch(purchaseStepSchema.currency);
  const quantityValue = watch(purchaseStepSchema.quantity);
  const availableInvestmentType = watch(purchaseStepSchema.availableInvestment);

  const priceText =
    productType === ProductType.BOND_PAPERS
      ? textTranslation('payment.pricePerBond')
      : textTranslation('payment.pricePerShare');

  const minimalAmount = useMemo(() => {
    return shareMinimalInvestment * (currency?.rate || 1);
  }, [currency?.rate, shareMinimalInvestment]);

  const currencyOptions = useMemo(() => {
    return (
      (item as ShareFullResponseDTO | BondFullResponseDTO | ShareResponseDTO).currencies ||
      (item as NftExtendedV5Response).nftCollection?.currencies ||
      []
    );
  }, [item]);

  const currencies = useMemo(() => {
    if (currencyOptions) {
      return mapCurrencyResponse(currencyOptions);
    }
  }, [currencyOptions]);

  useEffect(() => {
    setCurrency(currencyOptions?.find((option) => option.currencyCode === currencyValue?.value));
  }, [currencyOptions, currencyValue, setCurrency]);

  useEffect(() => {
    setIsButtonDisabled(!quantity || isErrorTotalAmount);
  }, [isErrorTotalAmount, quantityValue, quantity, setIsButtonDisabled]);

  const errorTotalAmount = useMemo(() => {
    const minimalErrorMsg = textTranslation('payment.minimalInvestmentError', {
      value: formatAmount(shareMinimalInvestment, 2, currency?.rate, currency?.currencyCode),
    });

    const maximalErrorMsg = textTranslation('payment.maxInvestmentError', {
      value: formatAmount(
        ProductType.BOND_PAPERS === productType ? maxInvestmentBond : maxInvestmentShare,
        2,
        currency?.rate,
        currency?.currencyCode
      ),
    });

    const errorTotalAmountStatus = isErrorMaxInvestment ? maximalErrorMsg : minimalErrorMsg;

    return errorTotalAmountStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, currency?.rate, currency?.currencyCode, textTranslation, isErrorMaxInvestment]);

  const productInfo = getProductInfo(productType, item, currency);

  const checkIfTotalAmountIsMoreThanMinimal = useCallback(() => {
    if (!quantity) return;
    const price = productInfo.rawProductPrice;

    if (productType === ProductType.BOND_PAPERS) {
      setIsErrorMaxInvestment(maxInvestmentBond < quantity * price);
    } else {
      setIsErrorMaxInvestment(maxInvestmentShare < quantity * price);
      setIsErrorMinInvestment(minimalAmount > quantity * price);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, productInfo.rawProductPrice, quantity, currency?.rate, isErrorTotalAmount]);

  const updateTotalAmount = useCallback(() => {
    const totalAmount = formatAmountWithoutCurrencyCode(
      quantity * productInfo.rawProductPrice,
      currency?.decimals,
      1,
      currency?.currencyCode
    );
    setTotalAmount(totalAmount || '0');
    checkIfTotalAmountIsMoreThanMinimal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    quantity,
    productInfo.rawProductPrice,
    currency,
    setTotalAmount,
    productType,
    checkIfTotalAmountIsMoreThanMinimal,
    availableInvestmentType,
  ]);

  const minimalShares = useMemo(() => {
    const calculateShares = minimalAmount / productInfo.rawProductPrice;
    return Math.ceil(calculateShares);
  }, [productInfo.rawProductPrice, minimalAmount]);

  const { availableInvestmentTypes, availableInvestmentTypesJsx } = useMemo(() => {
    const valueString = `${minimalShares} Shares - ${minimalAmount} ${currency?.currencyCode}`;
    const valueJsx = (
      <>
        <strong>{minimalShares}</strong> Shares - <strong>{minimalAmount}</strong> {currency?.currencyCode}
      </>
    );

    return {
      availableInvestmentTypes: [{ id: 'SELLING_SHARES', value: valueString }],
      availableInvestmentTypesJsx: [{ id: 'SELLING_SHARES', value: valueJsx }],
    };
  }, [currency?.currencyCode, minimalShares, minimalAmount]);

  const clearMinimalInvestment = useCallback(() => {
    if (quantity !== Number(minimalShares)) {
      setValue('availableInvestment', { id: '', value: '' }, { shouldValidate: false, shouldDirty: false });
    }
  }, [minimalShares, quantity, setValue]);

  const handleMinimalSelectedAmount = useCallback(() => {
    const hasInvestment = getValues('availableInvestment.value');

    setValue('quantity', hasInvestment ? '' : minimalShares.toString(), {
      shouldValidate: !hasInvestment,
      shouldDirty: !hasInvestment,
    });
    setValue('availableInvestment', hasInvestment ? { id: '', value: '' } : availableInvestmentTypes[0], {
      shouldValidate: !hasInvestment,
      shouldDirty: !hasInvestment,
    });
  }, [availableInvestmentTypes, getValues, minimalShares, setValue]);

  useEffect(() => {
    updateTotalAmount();
    setQuantity(+quantityValue);
  }, [quantityValue, updateTotalAmount, setQuantity]);

  useEffect(() => {
    clearMinimalInvestment();
  }, [clearMinimalInvestment, quantity]);

  return (
    <PurchaseContainer>
      <PriceHolder text={priceText} price={productInfo.productPrice} />
      <PurchaseStepWrapper>
        <OneLineElement>
          <FormSelectionPopup<PurchaseFormData>
            name={purchaseStepSchema.currency as Path<PurchaseFormData>}
            error={errors[purchaseStepSchema.currency] as FieldError}
            control={control}
            renderStartIcon={() => <ThemedIcon icon={CurrencyChfIcon} palette="neutrals" strokeColor="hue700" />}
            renderEndIcon={() => <ThemedIcon icon={CurrencyCoinCentIcon} customStrokeColor={theme.v2.icon.primary} />}
            itemsList={currencies ?? []}
            title={textTranslation('global.currency')}
            searchText={textTranslation('global.search')}
            closeButtonText={textTranslation('payment.cancel')}
            selectButtonText={textTranslation('payment.select')}
            fill
            isSearchEnabled={false}
            label={textTranslation('home.currency')}
            isFullHeight={false}
          />
        </OneLineElement>
        <QuantityValidation
          control={control}
          quantityName={purchaseStepSchema.quantity}
          error={errors[purchaseStepSchema.quantity]}
          totalAmountError={isErrorTotalAmount}
          totalAmoutMsgError={errorTotalAmount}
          productType={productType}
          maxInvestmentProductLength={maxInvestmentProductLength}
        />
        {productType === ProductType.ACTIONS && (
          <OneLineElement>
            <Flex flexDirection="column" gap="0.5rem">
              <Text fontWeight={'bold'}>{textTranslation('payment.availableInvestment')}</Text>
              <SelectionCard $disabled={false} onClick={() => handleMinimalSelectedAmount()}>
                <RadioButton
                  onSelect={(e) => e.stopPropagation()}
                  selected={availableInvestmentType}
                  label={availableInvestmentTypesJsx[0]}
                  zIndex
                />
              </SelectionCard>
            </Flex>
          </OneLineElement>
        )}
      </PurchaseStepWrapper>
      <Flex justifyContent="space-between">
        <Text>{textTranslation('payment.totalAmount')}</Text>
        <Text fontSize={'h4'} lineHeight={'h4'} fontWeight={'bold'}>
          {totalAmount} {''}
          {currency?.currencyCode}
        </Text>
      </Flex>
    </PurchaseContainer>
  );
};
export const PurchaseStep = () => {
  const appSelectedCurrency = useAppState((state: AppState) => state.currency);
  const { currency: selectedCurrency, quantity, totalAmount } = usePaymentContext();
  const currency = selectedCurrency || appSelectedCurrency;
  const defaultCurrency = currency ? mapCurrencyResponse([currency])[0] : { value: '', label: '' };
  const defaultValues = {
    currency: defaultCurrency,
    quantity: quantity > 0 ? quantity : null,
    totalAmount,
  };

  return (
    <ControlledForm validationSchema={createPurchaseStepSchema} defaultValues={defaultValues}>
      <PurchaseStepForm />
    </ControlledForm>
  );
};

export const PurchaseStepWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 0.5rem;
`;

export const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.25rem;
`;
