import { TenantThemeAndPermissionsResponse } from '@metaswiss/api';
import { useQueryClient } from '@tanstack/react-query';

import { ApiResource } from '../../enums/resource.enum';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

export const usePermissions = () => {
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<TenantThemeAndPermissionsResponse>(getQueryKey(ApiResource.TENANT_THEME));

  return queryData?.permissions || {};
};
