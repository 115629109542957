import { Heading, Text } from '@metaswiss/ui-kit';
import { useTheme } from 'styled-components';

import { AppState, useAppState } from '../../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';

import { HeaderContainer } from './header.styles';

export const Header = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const user = useAppState((state: AppState) => state.user);

  return (
    <HeaderContainer>
      <Heading
        headingType={'h4'}
        fontWeight={'bold'}
        lineHeight={'h4'}
        color={theme.v2.text.headingPrimary}
      >{`${textTranslation('home.title')} ${user?.displayName}`}</Heading>
      <Text lineHeight={'base'} fontSize={'base'} color={theme.v2.text.bodySecondary}>
        {textTranslation('home.subtitle')}
      </Text>
    </HeaderContainer>
  );
};
