import { BondFullResponseDTO, CurrencyResponse, NftExtendedV5Response, ShareFullResponseDTO } from '@metaswiss/api';
import { AppWizardProvider } from '@metaswiss/ui-kit';
import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { AddressType } from '../../../../enums/addressType.enum';
import { PaymentMethod } from '../../../../enums/paymentMethod.enum';
import { ProductType } from '../../../../enums/productType.enum';
import { AppState, useAppState } from '../../../../global-state/zustand';

export type PaymentTypeOption = {
  id: PaymentMethod;
  value: string;
};

export type AddressTypeOption = {
  id: AddressType;
  value: string;
};

type DeliveryAddress = {
  country: {
    id: string;
    name: string;
  };
  city: string;
  address: string;
  zipCode: string;
};

type PaymentContextProps = {
  quantity: number;
  setQuantity: (quantity: number) => void;
  productType: ProductType;
  setProductType: (productType: ProductType) => void;
  newDeliveryAddress: DeliveryAddress;
  setNewDeliveryAddress: (newDeliveryAddress: DeliveryAddress) => void;
  addressType: AddressType;
  setAddressType: (addressType: AddressType) => void;
  currency?: CurrencyResponse;
  setCurrency: (currency?: CurrencyResponse) => void;
  paymentMethod: PaymentMethod;
  setPaymentMethod: (paymentType: PaymentMethod) => void;
  step: number;
  setStep: (step: number) => void;
  item?: BondFullResponseDTO | ShareFullResponseDTO | NftExtendedV5Response;
  setItem: (item: BondFullResponseDTO | ShareFullResponseDTO | NftExtendedV5Response) => void;
  deliveryAddressId?: string;
  setDeliveryAddressId: (deliveryAddressId: string) => void;
  isError: boolean;
  setIsError: (isError: boolean) => void;
  totalAmount: string;
  setTotalAmount: (totalAmount: string) => void;
  clientSecret: string;
  setClientSecret: (secretId: string) => void;
  paymentIntentId: string;
  setPaymentIntentId: (intentId: string) => void;
  transactionId: string;
  setTransactionId: (transactionId: string) => void;
};

export const PaymentContext = createContext({} as PaymentContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export const usePaymentContext = () => useContext<PaymentContextProps>(PaymentContext);

export const PaymentContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const user = useAppState((state: AppState) => state.user);
  const defaultCurrency = useAppState((state: AppState) => state.currency);
  const userHasAddress = useMemo(() => !!user?.address && !!user?.city && !!user?.zipCode && !!user?.country, [user]);

  const [quantity, setQuantity] = useState<number>(0);
  const [productType, setProductType] = useState<ProductType>(ProductType.NFT);
  const [newDeliveryAddress, setNewDeliveryAddress] = useState<DeliveryAddress>({
    country: {
      id: '',
      name: '',
    },
    address: '',
    zipCode: '',
    city: '',
  });
  const [addressType, setAddressType] = useState<AddressType>(
    userHasAddress ? AddressType.DELIVERY_ADDRESS : AddressType.NEW_DELIVERY_ADDRESS
  );
  const [currency, setCurrency] = useState<CurrencyResponse | undefined>(defaultCurrency);
  const [totalAmount, setTotalAmount] = useState<string>('');
  const [item, setItem] = useState<BondFullResponseDTO | ShareFullResponseDTO | NftExtendedV5Response | undefined>(
    undefined
  );
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.STRIPE);
  const [step, setStep] = useState<number>(1);
  const [deliveryAddressId, setDeliveryAddressId] = useState<string | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [paymentIntentId, setPaymentIntentId] = useState<string>('');
  const [transactionId, setTransactionId] = useState<string>('');

  return (
    <PaymentContext.Provider
      value={{
        paymentMethod,
        setPaymentMethod,
        productType,
        setProductType,
        quantity,
        setQuantity,
        currency,
        setCurrency,
        newDeliveryAddress,
        setNewDeliveryAddress,
        addressType,
        setAddressType,
        item,
        setItem,
        step,
        setStep,
        deliveryAddressId,
        setDeliveryAddressId,
        isError,
        setIsError,
        totalAmount,
        setTotalAmount,
        clientSecret,
        setClientSecret,
        paymentIntentId,
        setPaymentIntentId,
        transactionId,
        setTransactionId,
      }}
    >
      <AppWizardProvider>{children}</AppWizardProvider>
    </PaymentContext.Provider>
  );
};
