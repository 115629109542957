import styled from 'styled-components';

export const StyledLegend = styled.legend<{ $isLocked: boolean; $hasContent: boolean; $isSmall: boolean }>`
  float: unset;
  width: auto;
  max-width: 0;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 0.125rem;
  font-size: ${({ theme }) => theme.typography.sizes.xsm};
  visibility: hidden;
  white-space: nowrap;
  margin-left: ${({ $isLocked, $isSmall }) => {
    if ($isLocked) {
      return '-0.5rem';
    }
    if ($isSmall) {
      return '0.35rem';
    }
    return '0.25rem';
  }};
  transition:
    ${({ $hasContent }) => $hasContent && 'max-width 0.2s ease-in-out'},
    margin 0.4s;
`;
