export type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

export const getDirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject
): UnknownArrayOrObject => {
  if (dirtyFields === true) {
    return allValues;
  }

  if (Array.isArray(dirtyFields)) {
    return allValues;
  }

  if (typeof allValues === 'object' && !Array.isArray(allValues)) {
    const dirtyEntries = Object.entries(dirtyFields)
      .map(([key, isDirty]) => {
        const value = getDirtyValues(isDirty, allValues[key] as UnknownArrayOrObject);

        return [key, value];
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => typeof value !== 'object' || Object.keys(value).length > 0);

    return Object.fromEntries(dirtyEntries);
  }

  return allValues;
};
