import { UserResponse } from '@metaswiss/api';
import { LoaderContainer } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { api } from '../../api/msApi';
import { getAccessToken } from '../../api/tokenHelpers';
import { ApiResource } from '../../enums/resource.enum';
import { AppState, useAppState } from '../../global-state/zustand';
import { routes } from '../../router/routes';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

import { Container } from './protectedRoute.styles';

type Props = {
  fallback?: string;
  customCondition?: boolean;
  children: React.ReactNode;
};

const ProtectedRoute: FC<Props> = ({ fallback, customCondition = true, children }) => {
  const accessToken = getAccessToken();
  const { setUser } = useAppState((state: AppState) => state);

  const { data: user, isLoading } = useQuery({
    queryKey: getQueryKey(ApiResource.USER_RESPONSE),
    queryFn: () => {
      return api.users.getUserResponse();
    },
    onSuccess: (response: UserResponse) => {
      setUser(response);
    },
    enabled: !!accessToken,
  });

  if (!accessToken) {
    return <Navigate to={routes.auth} />;
  }

  if (isLoading) {
    return (
      <Container>
        <LoaderContainer />
      </Container>
    );
  }

  if (!user) {
    return <Navigate to={routes.auth} />;
  }

  if (user?.firstTimeLogin) {
    return <Navigate to={routes.changePassword} />;
  }

  if (!customCondition) {
    return <Navigate to={fallback || '/'} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
